import React, { useRef } from 'react';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { updateIncidentFileIDs } from '../../../ApiServices/ApiServices';
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const region = process.env.REACT_APP_AWS_REGION;
const bucketName = 'ciservicedesk';

const UploadButton = ( { incidentID }) => {
  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadFileToS3(file);
    }
  };


const uploadFileToS3 = async (file) => {
  const s3 = new AWS.S3({
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      region: region,
  });

  const uuid = uuidv4(); 
  const fileName = `${uuid}.${file.name.split('.').pop()}`; 
  console.log("IncID:", incidentID);
  console.log("UUID: ", uuid);
  
  await updateIncidentFileIDs(incidentID, uuid);
  const params = {
      Bucket: bucketName,
      Key: fileName, // Use generated UUID-based file name
      Body: file,
  };

  s3.upload(params, async (err, data) => {
      if (err) {
          console.error('Error uploading file to S3:', err);
          alert('Upload failed. Please try again.');
      } else {
          console.log('File uploaded successfully:', data.Location);
          alert('Upload successful!');
          
          window.location.reload();
      }
  });
};
  return (
    <div>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileSelect}
      />
      <button onClick={() => fileInputRef.current.click()}>Choose Photo</button>
    </div>
  );
};

export default UploadButton;
