import React, { useState, useEffect, useMemo } from "react";
import {
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Button,
    Typography,
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    LinearProgress,
    InputAdornment,
    IconButton,
    Chip,
    Tooltip,
    DialogActions,
    DialogTitle,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { useUser } from '../../../UserContext'; // Adjust the import path as needed
import accessRightsData from '../Data/accessRights.json'; // Adjust the import path as needed
import { updateUser } from '../../ApiServices/ApiServices';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
}));

const licenseKey = process.env.REACT_APP_LICENSE_KEY;

const StyledTextField = styled(TextField)({
    "& .MuiInput-underline:before": {
        borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
        borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
    },
});


const addUser = async (user) => {
    // Simulating API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    console.log('User added:', user);
    return user;
};

const deleteUser = async (userId) => {
    // Simulating API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    console.log('User deleted:', userId);
    return userId;
};

const AccessRights = () => {
    const { allUsers } = useUser();
    const [users, setUsers] = useState([]);
    const [editedUsers, setEditedUsers] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [newUser, setNewUser] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [accessRightsDialogOpen, setAccessRightsDialogOpen] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!allUsers || !allUsers.response || !Array.isArray(allUsers.response)) {
            setError("User data is not available or in an incorrect format.");
        } else if (allUsers.response.length === 0) {
            setError("No users found.");
        } else {
            setUsers(allUsers.response.map(user => ({
                ...user,
                AccessRights: JSON.parse(user.AccessRights || '[]')
            })));
            setError(null);
        }
        setLoading(false);
    }, [allUsers]);

    const handleEdit = (userId, field, value) => {
        setEditedUsers((prev) => ({
            ...prev,
            [userId]: {
                ...(prev[userId] || {}),
                [field]: value,
            },
        }));
    };

    const handleSave = async (userId) => {
        setSaving(true);
        try {
            const userToUpdate = {
                ...users.find(user => user.EmailAddress === userId),
                ...editedUsers[userId]
            };
            const updatedUser = await updateUser(userToUpdate);
            setUsers(prevUsers =>
                prevUsers.map(user =>
                    user.EmailAddress === userId ? {
                        ...updatedUser,
                        AccessRights: JSON.parse(updatedUser.AccessRights || '[]')
                    } : user
                )
            );
            setEditedUsers(prev => {
                const newEdited = { ...prev };
                delete newEdited[userId];
                return newEdited;
            });
        } catch (error) {
            setError("Failed to update user. Please try again.");
        } finally {
            setSaving(false);
        }
    };
    
    const handleAddNewUser = () => {
        setNewUser({
            EmailAddress: "",
            Phone: "",
            FirstName: "",
            LastName: "",
            AccessRights: [],
        });
    };

    const handleSaveNewUser = async () => {
        setSaving(true);
        try {
            const addedUser = await addUser({
                ...newUser,
                AccessRights: JSON.stringify(newUser.AccessRights)
            });
            setUsers(prevUsers => [...prevUsers, {
                ...addedUser,
                AccessRights: JSON.parse(addedUser.AccessRights || '[]')
            }]);
            setNewUser(null);
        } catch (error) {
            setError("Failed to add new user. Please try again.");
        } finally {
            setSaving(false);
        }
    };

    const handleDeleteUser = (user) => {
        setUserToDelete(user);
        setDeleteDialogOpen(true);
    };

    const confirmDelete = async () => {
        setSaving(true);
        try {
            await deleteUser(userToDelete.EmailAddress);
            setUsers(prevUsers =>
                prevUsers.filter(user => user.EmailAddress !== userToDelete.EmailAddress)
            );
            setDeleteDialogOpen(false);
            setUserToDelete(null);
        } catch (error) {
            setError("Failed to delete user. Please try again.");
        } finally {
            setSaving(false);
        }
    };

    const handleEditAccessRights = (user) => {
        setEditingUser(user);
        setAccessRightsDialogOpen(true);
    };

    const handleSaveAccessRights = async () => {
        setSaving(true);
        try {
            const updatedUser = await updateUser(editingUser);
            setUsers(prevUsers =>
                prevUsers.map(user =>
                    user.EmailAddress === updatedUser.EmailAddress ? {
                        ...updatedUser,
                        AccessRights: JSON.parse(updatedUser.AccessRights || '[]')
                    } : user
                )
            );
            setAccessRightsDialogOpen(false);
            setEditingUser(null);
        } catch (error) {
            setError("Failed to update access rights. Please try again.");
        } finally {
            setSaving(false);
        }
    };

    const filteredUsers = useMemo(() => {
        return users.filter((user) =>
            Object.entries(user).some(
                ([key, value]) =>
                    key !== 'AccessRights' &&
                    typeof value === "string" &&
                    value.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [users, searchTerm]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                User Access Rights Management
            </Typography>
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
                <TextField
                    variant="outlined"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Tooltip title="Add New User">
                    <IconButton onClick={handleAddNewUser}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="users table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Email Address</StyledTableCell>
                            <StyledTableCell>Phone</StyledTableCell>
                            <StyledTableCell>First Name</StyledTableCell>
                            <StyledTableCell>Last Name</StyledTableCell>
                            <StyledTableCell>Access Rights</StyledTableCell>
                            <StyledTableCell>Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {newUser && (
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={newUser.EmailAddress}
                                        onChange={(e) =>
                                            setNewUser((prev) => ({
                                                ...prev,
                                                EmailAddress: e.target.value,
                                            }))
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={newUser.Phone}
                                        onChange={(e) =>
                                            setNewUser((prev) => ({
                                                ...prev,
                                                Phone: e.target.value,
                                            }))
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={newUser.FirstName}
                                        onChange={(e) =>
                                            setNewUser((prev) => ({
                                                ...prev,
                                                FirstName: e.target.value,
                                            }))
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={newUser.LastName}
                                        onChange={(e) =>
                                            setNewUser((prev) => ({
                                                ...prev,
                                                LastName: e.target.value,
                                            }))
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditAccessRights(newUser)}>
                                        Edit Access Rights
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        color="primary"
                                        onClick={handleSaveNewUser}
                                        disabled={!newUser.EmailAddress || !newUser.FirstName || !newUser.LastName}
                                    >
                                        <SaveIcon />
                                    </IconButton>
                                    <IconButton onClick={() => setNewUser(null)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                        {filteredUsers.map((user) => (
                            <TableRow key={user.EmailAddress}>
                                <TableCell>
                                    <StyledTextField
                                        fullWidth
                                        variant="standard"
                                        value={editedUsers[user.EmailAddress]?.EmailAddress ?? user.EmailAddress}
                                        onChange={(e) =>
                                            handleEdit(user.EmailAddress, "EmailAddress", e.target.value)
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <StyledTextField
                                        fullWidth
                                        variant="standard"
                                        value={editedUsers[user.EmailAddress]?.Phone ?? user.Phone}
                                        onChange={(e) =>
                                            handleEdit(user.EmailAddress, "Phone", e.target.value)
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <StyledTextField
                                        fullWidth
                                        variant="standard"
                                        value={editedUsers[user.EmailAddress]?.FirstName ?? user.FirstName}
                                        onChange={(e) =>
                                            handleEdit(user.EmailAddress, "FirstName", e.target.value)
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <StyledTextField
                                        fullWidth
                                        variant="standard"
                                        value={editedUsers[user.EmailAddress]?.LastName ?? user.LastName}
                                        onChange={(e) =>
                                            handleEdit(user.EmailAddress, "LastName", e.target.value)
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    {user.AccessRights.length > 0 ? (
                                        user.AccessRights.map(right => (
                                            <Chip key={right} label={right} sx={{ m: 0.5 }} />
                                        ))
                                    ) : (
                                        'None'
                                    )}
                                    <IconButton onClick={() => handleEditAccessRights(user)}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Save">
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleSave(user.EmailAddress)}
                                            disabled={!editedUsers[user.EmailAddress]}
                                        >
                                            <SaveIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton
                                            color="error"
                                            onClick={() => handleDeleteUser(user)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={saving} fullWidth maxWidth="sm">
                <DialogContent>
                    <Typography variant="h6" gutterBottom>
                        Processing Request
                    </Typography>
                    <Box sx={{ width: "100%", mt: 2 }}>
                        <LinearProgress />
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogContent>
                    <Typography>Are you sure you wish to delete this user?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={confirmDelete} color="error">
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={accessRightsDialogOpen}
                onClose={() => setAccessRightsDialogOpen(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Edit Access Rights</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        {accessRightsData.AccessRights.map((right) => (
                            <FormControlLabel
                                key={right}
                                control={
                                    <Checkbox
                                        checked={editingUser?.AccessRights.includes(right) || false}
                                        onChange={(e) => {
                                            const newAccessRights = e.target.checked
                                                ? [...editingUser.AccessRights, right]
                                                : editingUser.AccessRights.filter(r => r !== right);
                                            setEditingUser(prev => ({
                                                ...prev,
                                                AccessRights: newAccessRights
                                            }));
                                        }}
                                    />
                                }
                                label={right}
                            />
                        ))}
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAccessRightsDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleSaveAccessRights} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default AccessRights;