import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { fetchAuthSession } from "aws-amplify/auth";
import {
  Box,
  Container,
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
  LinearProgress,
} from "@mui/material";
import ReportingForm from "./Components/ReportingForm";
import StatusChart from "./Components/StatusChart";
import LevelsChart from "./Components/LevelsChart";
import ChevronProgress from "./Components/ChevronProgress";
import ServiceTable from "./Components/ServiceTable";
import ProgressGauges from "./Components/ProgressGauge";
import Locations from "../Data/locations.json";
import AssignmentGroups from "../Data/AssignmentGroups.json";
import ServiceTypes from "../Data/servicesDatabase.json";
import statusData from "../Data/srStatus.json";
import priorityData from "../Data/priority.json";
import {
  fetchUsers,
  fetchTasks,
  fetchProjects,
} from "../../ApiServices/ApiServices";
import MultiServiceLevelsChart from "./Components/MultiServiceLevelChart";
import MultiStatusChart from "./Components/MultiStatusChart";
import ServiceStatusCharts from "./Components/ServiceStatusCharts";

export default function Reporting({ signOut, userName, colorMode }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const licenseKey =
    "8650c58b40f5c8d3e3325c7ce4c3e6e2cb88ba80b9f4d544dcc0dfae76acd8c23797d5ceada346b74919ef9392ab5d410122d067defacec4a0545316f8c57553";

  // Form state
  const [organization, setOrganization] = useState("");
  const [location, setLocation] = useState("");
  const [assignmentGroup, setAssignmentGroup] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [requestNumber, setRequestNumber] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [projectNumber, setProjectNumber] = useState("");
  const [projects, setProjects] = useState([]);

  // Dropdown options
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [assignmentGroupOptions, setAssignmentGroupOptions] = useState([]);
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  // Table state
  const [services, setServices] = useState([]);

  // Loading state
  const [loading, setLoading] = useState(false);

  // Chart data
  const [statusChartData, setStatusChartData] = useState([]);
  const [levelsChartData, setLevelsChartData] = useState([]);

  // Levels loading state
  const [showLevelsLoading, setShowLevelsLoading] = useState(false);
  const [showLevelsProgress, setShowLevelsProgress] = useState(0);
  const [showLevelsChart, setShowLevelsChart] = useState(false);

  const [allTasks, setAllTasks] = useState([]);
  const [serviceTypeLevelsData, setServiceTypeLevelsData] = useState({});

  //set up project dropdown
  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const data = await fetchProjects();
        setProjects(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching Projects:", error);
      }
    };

    fetchProjectData();
  }, []);

  useEffect(() => {
    setOrganizationOptions(Object.keys(Locations));
    setAssignmentGroupOptions(
      AssignmentGroups.map((group) => group.AssignmentGroup)
    );
    //Define Services
    var services = [];
    services = ServiceTypes.response[0].Network.concat(
      ServiceTypes.response[0]["Physical Security"].concat(
        ServiceTypes.response[0]["Hardware Installations"]
      )
    );
    setServiceTypeOptions(services.map((service) => service["Service Type"]));

    const fetchAllUsers = async () => {
      try {
        const users = await fetchUsers(licenseKey);
        setUserOptions(
          users.response.map((user) => ({
            label: `${user.FirstName} ${user.LastName}`,
            value: user.EmailAddress,
          }))
        );
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchAllUsers();
  }, []);

  useEffect(() => {
    if (organization) {
      const options = Locations[organization].BUILDINGS.map(
        (building) => building["Building Name"]
      );
      setLocationOptions(options);
    } else {
      setLocationOptions([]);
    }
  }, [organization]);

  useEffect(() => {
    if (services.length > 0) {
      const statusCounts = services.reduce((acc, service) => {
        acc[service.ServiceStatus] = (acc[service.ServiceStatus] || 0) + 1;
        return acc;
      }, {});

      const chartData = Object.entries(statusCounts).map(([status, count]) => ({
        value: count,
        label: status,
        color: getStatusColor(status),
      }));

      setStatusChartData(chartData);
    }
  }, [services]);

  const handleGenerateReport = async () => {
    try {
      setLoading(true);
      const { idToken } = (await fetchAuthSession()).tokens ?? {};

      const payload = {
        "Organization-index": organization || "NA",
        "Location-index": location || "NA",
        "AssignedGroup-index": assignmentGroup || "NA",
        "RequestType-index": serviceType || "NA",
        "RequestNumber-index": requestNumber
          ? `REQ${requestNumber.padStart(5, "0")}`
          : "NA",
        "AssignedTo-index": assignedTo || "NA",
        "ProjectNumber-index": projectNumber || "NA",
      };

      const apiUrl =
        "https://jprmf4dlng.execute-api.us-east-2.amazonaws.com/Prod/queryServices";

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to generate report");
      }

      const data = await response.json();
      console.log("Report generated:", data);

      if (data && data.body && data.body.Items) {
        setServices(data.body.Items);
      } else {
        setServices([]);
        console.warn("No items found in the response");
      }
    } catch (error) {
      console.error("Error generating report:", error.message);
      setServices([]);
    } finally {
      setLoading(false);
    }
  };

  const handleShowLevels = async () => {
    setShowLevelsLoading(true);
    setShowLevelsProgress(0);
    const requestIds = services.map((service) => service.RequestID);
    const totalRequests = requestIds.length;
    let completedRequests = 0;
    const allTasks = [];
  
    for (const requestId of requestIds) {
      try {
        const tasksData = await fetchTasks(requestId);
        allTasks.push(...(tasksData.body || []));
        completedRequests++;
        setShowLevelsProgress((completedRequests / totalRequests) * 100);
      } catch (error) {
        console.error(`Error fetching tasks for request ${requestId}:`, error);
      }
    }
  
    const newServiceTypeLevelsData = processServiceTypeLevelsData(services, allTasks);
    setServiceTypeLevelsData(newServiceTypeLevelsData);
    setAllTasks(allTasks);
    setShowLevelsLoading(false);
    setShowLevelsChart(true);
  };

  const processServiceTypeLevelsData = (services, tasks) => {
    const newServiceTypeLevelsData = {};

    services.forEach(service => {
      const serviceTasks = tasks.filter(task => task.RequestID === service.RequestID);
      
      if (!newServiceTypeLevelsData[service.RequestType]) {
        newServiceTypeLevelsData[service.RequestType] = {};
      }

      serviceTasks.forEach(task => {
        const taskKey = `${task.TaskType}-${task.Level}`; // Create a unique key for each task type and level combination
        if (!newServiceTypeLevelsData[service.RequestType][taskKey]) {
          newServiceTypeLevelsData[service.RequestType][taskKey] = {
            taskType: task.TaskType,
            level: task.Level,
            statusCounts: {},
            totalTasks: 0
          };
        }
        
        const taskGroup = newServiceTypeLevelsData[service.RequestType][taskKey];
        taskGroup.statusCounts[task.TaskStatus] = (taskGroup.statusCounts[task.TaskStatus] || 0) + 1;
        taskGroup.totalTasks += 1;
      });
    });

    // Ensure all status types are represented in statusCounts
    Object.values(newServiceTypeLevelsData).forEach(serviceType => {
      Object.values(serviceType).forEach(taskGroup => {
        statusData.status.forEach(status => {
          if (!taskGroup.statusCounts[status.name]) {
            taskGroup.statusCounts[status.name] = 0;
          }
        });
      });
    });

    return newServiceTypeLevelsData;
  };
  


  const processLevelsData = (allTasks) => {
    const levelCounts = {};
    const levelCompletedCounts = {};

    Object.values(allTasks)
      .flat()
      .forEach((task) => {
        const level = task.Level;
        levelCounts[level] = (levelCounts[level] || 0) + 1;
        if (task.TaskStatus === "Completed") {
          levelCompletedCounts[level] = (levelCompletedCounts[level] || 0) + 1;
        }
      });

    const chartData = Object.keys(levelCounts)
      .sort()
      .map((level) => ({
        level: `Level ${level}`,
        completionPercentage:
          ((levelCompletedCounts[level] || 0) / levelCounts[level]) * 100,
      }));

    setLevelsChartData(chartData);
  };

  const getStatusColor = (statusName) => {
    const status = statusData.status.find((s) => s.name === statusName);
    return status ? status.color : theme.palette.text.primary;
  };

  const getPriorityColor = (priorityName) => {
    const priority = priorityData.find((p) => p.priority === priorityName);
    return priority ? priority.color : theme.palette.text.primary;
  };

  const convertToCSV = (data) => {
    if (data.length === 0) return "";

    const headers = [
      "RequestNumber",
      "RequestType",
      "RequestDescription",
      "Priority",
      "ServiceStatus",
      "RequestID",
      "AssignedTo",
      "RequesterName",
      "RequesterEmail",
      "CreationTime",
      "DueDate",
      "Organization",
      "Location",
      "AssignedGroup",
    ];

    const csvRows = [];
    csvRows.push(headers.join(","));
    data.forEach((item) => {
      const row = headers.map((header) => {
        let cell = item[header] || "";
        cell = cell.toString().replace(/"/g, '""');
        return `"${cell}"`;
      });
      csvRows.push(row.join(","));
    });
    return csvRows.join("\n");
  };

  const downloadCSVFile = (csv, filename) => {
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const handleDownloadSelected = (selected) => {
    const selectedServices = services.filter((service) =>
      selected.includes(service.RequestID)
    );
    const csv = convertToCSV(selectedServices);
    downloadCSVFile(csv, "selected_service_requests.csv");
  };

  const handleDownloadAll = () => {
    const csv = convertToCSV(services);
    downloadCSVFile(csv, "all_service_requests.csv");
  };

  return (
    <Container maxWidth="xl" sx={{ py: 3, px: { xs: 1, sm: 2, md: 3 } }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ReportingForm
            organization={organization}
            setOrganization={setOrganization}
            location={location}
            setLocation={setLocation}
            assignmentGroup={assignmentGroup}
            setAssignmentGroup={setAssignmentGroup}
            serviceType={serviceType}
            setServiceType={setServiceType}
            requestNumber={requestNumber}
            setRequestNumber={setRequestNumber}
            assignedTo={assignedTo}
            setAssignedTo={setAssignedTo}
            projectNumber={projectNumber}
            setProjectNumber={setProjectNumber}
            organizationOptions={organizationOptions}
            locationOptions={locationOptions}
            assignmentGroupOptions={assignmentGroupOptions}
            serviceTypeOptions={serviceTypeOptions}
            userOptions={userOptions}
            projects={projects}
            handleGenerateReport={handleGenerateReport}
            loading={loading}
          />
        </Grid>

        {loading && (
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
            </Box>
          </Grid>
        )}

        {!loading && services.length > 0 && (
          <>
            <Grid item xs={12}>
              <StatusChart statusChartData={statusChartData} />
            </Grid>

            <Grid item xs={12}>
              <MultiStatusChart services={services} />
            </Grid>


            <Grid item xs={12}>
              <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleShowLevels}
                  disabled={showLevelsLoading}
                >
                  Show Levels
                </Button>
              </Box>
            </Grid>

            {showLevelsChart && (
              <>

<Grid item xs={12}>
              <ServiceStatusCharts
                serviceTypeLevelsData={serviceTypeLevelsData}
              />
            </Grid>
              </>
            )}

            <Grid item xs={12}>
              <ServiceTable
                services={services}
                getStatusColor={getStatusColor}
                getPriorityColor={getPriorityColor}
                onDownloadSelected={handleDownloadSelected}
                onDownloadAll={handleDownloadAll}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Dialog open={showLevelsLoading} fullWidth maxWidth="sm">
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Fetching Tasks
          </Typography>
          <Typography variant="body2" gutterBottom>
            Progress: {Math.round(showLevelsProgress)}%
          </Typography>
          <Box sx={{ width: "100%", mt: 2 }}>
            <LinearProgress variant="determinate" value={showLevelsProgress} />
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
