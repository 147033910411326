import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createIncident, fetchUsers } from "../../ApiServices/ApiServices";
import { fetchAuthSession } from "aws-amplify/auth";
import IncidentTypes from "../Data/IncidentTypes.json";
import Priority from "../Data/priority.json";
import Locations from "../Data/locations.json";
import AssignmentGroups from "../Data/AssignmentGroups.json";
import {
  Container, Grid, Card, CardContent, Typography, Select, MenuItem, TextField,
  Button, Box, CircularProgress, FormControl, InputLabel, Stack
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const NewIncident = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const licenseKey = "8650c58b40f5c8d3e3325c7ce4c3e6e2cb88ba80b9f4d544dcc0dfae76acd8c23797d5ceada346b74919ef9392ab5d410122d067defacec4a0545316f8c57553";

  const [currentUser, setCurrentUser] = useState('');
  const [currentUserInfo, setCurrentUserInfo] = useState(null);
  const [userInfo, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState('');
  const [selectedPriority, setSelectedPriority] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [assignee, setAssignee] = useState('');
  const [selectedAssignmentGroup, setSelectedAssignmentGroup] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [note, setNote] = useState('');

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const users = await fetchUsers(licenseKey);
        setUsers(users.response);
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const email = idToken.payload.email;
        setCurrentUser(email);
        const requester = users.response.find(user => user.EmailAddress === email);
        setCurrentUserInfo(requester);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };
    fetchAllUsers();
  }, []);

  const calculateDueDate = (responseTime) => {
    const currentDate = new Date();
    const hoursToAdd = parseInt(responseTime.match(/\d+/)[0]);
    const dueDate = new Date(currentDate.getTime() + hoursToAdd * 60 * 60 * 1000);
    return dueDate.toLocaleString();
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const currentDate = new Date().toLocaleString();
      const selectedPriorityData = Priority.find(item => item.priority === selectedPriority);
      const dueDate = calculateDueDate(selectedPriorityData.responseTime);
      const incDesc = IncidentTypes.find(type => type.name === selectedType)?.description;

      const payload = {
        DueDate: dueDate,
        CreationTime: currentDate,
        IncidentType: selectedType,
        Priority: selectedPriority,
        Impact: "1",
        AssignedTo: assignee,
        IncidentDescription: incDesc,
        RequesterName: `${currentUserInfo.FirstName} ${currentUserInfo.LastName}`,
        RequesterEmail: currentUserInfo.EmailAddress,
        RequesterPhoneNumber: currentUserInfo.Phone,
        RelatedTickets: [],
        IncidentStatus: "New",
        Organization: selectedCustomer,
        Location: selectedLocation,
        AssignedGroup: selectedAssignmentGroup,
        Note: note || "No Initial Description"
      };

      const response = await createIncident(payload);
      if (response.response === "Success") {
        alert("Incident submitted successfully!");
        navigate("/incidents");
      } else {
        throw new Error("Failed to submit Incident");
      }
    } catch (error) {
      console.error("Error submitting Incident:", error.message);
      alert("Failed to submit incident. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ pt: 3, pb: 5 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>Create New Incident</Typography>
              <Typography variant="h6">Requester Info</Typography>
              <Typography>Name: {currentUserInfo?.FirstName || "N/A"} {currentUserInfo?.LastName || "N/A"}</Typography>
              <Typography>Email: {currentUser}</Typography>
              <Typography>Phone: {currentUserInfo?.Phone || "N/A"}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <InputLabel>Select Incident Type</InputLabel>
                <Select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                  <MenuItem value="">Select...</MenuItem>
                  {IncidentTypes.map((type, index) => (
                    <MenuItem key={index} value={type.name}>{type.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedType && (
                <Box mt={2}>
                  <Typography variant="h6">{selectedType}</Typography>
                  <Typography>Description: {IncidentTypes.find(type => type.name === selectedType)?.description}</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <InputLabel>Select Priority</InputLabel>
                <Select 
                  value={selectedPriority} 
                  onChange={(e) => setSelectedPriority(e.target.value)}
                  sx={{
                    color: Priority.find(p => p.priority === selectedPriority)?.color,
                    '&:before': { borderColor: Priority.find(p => p.priority === selectedPriority)?.color },
                    '&:after': { borderColor: Priority.find(p => p.priority === selectedPriority)?.color },
                  }}
                >
                  <MenuItem value="">Select...</MenuItem>
                  {Priority.map((type, index) => (
                    <MenuItem key={index} value={type.priority} style={{ color: type.color }}>{type.priority}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedPriority && (
                <Typography>Description: {Priority.find(type => type.priority === selectedPriority)?.description}</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <InputLabel>Assignment Group</InputLabel>
                <Select value={selectedAssignmentGroup} onChange={(e) => setSelectedAssignmentGroup(e.target.value)}>
                  <MenuItem value="">Select...</MenuItem>
                  {AssignmentGroups.map((group, index) => (
                    <MenuItem key={index} value={group.AssignmentGroup}>{group.AssignmentGroup}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedAssignmentGroup && (
                <Typography>Members: {AssignmentGroups.find(group => group.AssignmentGroup === selectedAssignmentGroup)?.Members.join(", ")}</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <InputLabel>Assign To</InputLabel>
                <Select value={assignee} onChange={(e) => setAssignee(e.target.value)}>
                  <MenuItem value="">Select...</MenuItem>
                  {userInfo.map((user, index) => (
                    <MenuItem key={index} value={user.EmailAddress}>{user.FirstName} {user.LastName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {assignee && (
                <Box mt={2}>
                  <Typography>First: {userInfo.find(user => user.EmailAddress === assignee)?.FirstName || "N/A"}</Typography>
                  <Typography>Last: {userInfo.find(user => user.EmailAddress === assignee)?.LastName || "N/A"}</Typography>
                  <Typography>Phone: {userInfo.find(user => user.EmailAddress === assignee)?.Phone || "N/A"}</Typography>
                  <Typography>Email: {userInfo.find(user => user.EmailAddress === assignee)?.EmailAddress || "N/A"}</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <InputLabel>Choose Organization</InputLabel>
                <Select value={selectedCustomer} onChange={(e) => { setSelectedCustomer(e.target.value); setSelectedLocation(""); }}>
                  <MenuItem value="">Select...</MenuItem>
                  {Object.keys(Locations).map((customer, index) => (
                    <MenuItem key={index} value={customer}>{customer}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <InputLabel>Choose Location</InputLabel>
                <Select value={selectedLocation} onChange={(e) => setSelectedLocation(e.target.value)} disabled={!selectedCustomer}>
                  <MenuItem value="">Select...</MenuItem>
                  {selectedCustomer && Locations[selectedCustomer].BUILDINGS.map((building, index) => (
                    <MenuItem key={index} value={building["Building Name"]}>{building["Building Name"].slice(0, 50)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <TextField
                fullWidth
                label="Initial Description"
                multiline
                rows={5}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Enter initial description here"
                inputProps={{ maxLength: 500 }}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ my: 2 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={submitting || !selectedCustomer || !selectedLocation || !assignee || !selectedPriority || !selectedType || !selectedAssignmentGroup}
              onClick={handleSubmit}
            >
              {submitting ? "Submitting..." : "Submit"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewIncident;