import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getUserTasks, updateTaskStatus, updateTaskAssignee } from '../../ApiServices/ApiServices';
import { useUser } from '../../../UserContext';
import {
  Box, Paper, Typography, Accordion, AccordionSummary, AccordionDetails,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, CircularProgress, TextField, InputAdornment, Select, MenuItem,
  LinearProgress, Grid, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import statusData from '../Data/srStatus.json';

const UserTasks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const navigate = useNavigate();
  const { user } = useUser();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [updatingStatus, setUpdatingStatus] = useState({});
  const [updateProgress, setUpdateProgress] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [overallProgress, setOverallProgress] = useState(0);

  useEffect(() => {
    const fetchTaskData = async () => {
      setLoading(true);
      try {
        if (user && user.EmailAddress) {
          const response = await getUserTasks(user.EmailAddress);
          if (response && response.body) {
            setTasks(response.body);
          } else {
            console.error("Unexpected response structure:", response);
            setTasks([]);
          }
        }
      } catch (error) {
        console.error("Error fetching Tasks:", error);
        setTasks([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTaskData();
  }, [user]);

  const groupedTasks = useMemo(() => {
    const grouped = tasks.reduce((acc, task) => {
      const key = `${task.TaskType}-${task.Building}-${task.Organization}-${task.ProjectName}-${task.TaskStatus}-${task.Floor}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(task);
      return acc;
    }, {});

    return Object.entries(grouped).map(([key, tasks]) => ({
      id: key,
      taskType: tasks[0].TaskType,
      building: tasks[0].Building,
      organization: tasks[0].Organization,
      projectName: tasks[0].ProjectName,
      taskStatus: tasks[0].TaskStatus,
      floor: tasks[0].Floor,
      tasks: tasks,
      count: tasks.length,
      taskMembers: JSON.parse(tasks[0].TaskMembers || '[]')
    })).sort((a, b) => {
      if (a.projectName !== b.projectName) return a.projectName.localeCompare(b.projectName);
      // Convert floor to number for comparison, with non-numeric values sorted last
      const floorA = isNaN(Number(a.floor)) ? Infinity : Number(a.floor);
      const floorB = isNaN(Number(b.floor)) ? Infinity : Number(b.floor);
      return floorA - floorB;
    });
  }, [tasks]);

  const filteredGroups = useMemo(() => {
    return groupedTasks.filter(group =>
      group.projectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      group.taskType.toLowerCase().includes(searchTerm.toLowerCase()) ||
      group.building.toLowerCase().includes(searchTerm.toLowerCase()) ||
      group.organization.toLowerCase().includes(searchTerm.toLowerCase()) ||
      group.taskStatus.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(group.floor).toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [groupedTasks, searchTerm]);

  const handleUpdateProgress = (current, total) => {
    const progress = Math.round((current / total) * 100);
    setOverallProgress(progress);
  };

  const getStatusColor = (status) => {
    const statusItem = statusData.status.find(item => item.name === status);
    return statusItem ? statusItem.color : '#000000';
  };

  const TaskGroup = ({ group }) => {
    const [expanded, setExpanded] = useState(false);
    const [groupStatus, setGroupStatus] = useState(group.taskStatus);
    const [updateCount, setUpdateCount] = useState('');
    const [groupAssignee, setGroupAssignee] = useState('');

    const handleAccordionChange = (event, isExpanded) => {
      setExpanded(isExpanded);
    };

    const handleGroupStatusChange = (event) => {
      const newStatus = event.target.value;
      setGroupStatus(newStatus);
    };

    const handleGroupAssigneeChange = (event) => {
      const newAssignee = event.target.value;
      setGroupAssignee(newAssignee);
    };

    const handleUpdateCountChange = (event) => {
      const value = event.target.value.replace(/^0+/, '');
      const numValue = parseInt(value, 10);
      
      if (value === '' || (numValue >= 1 && numValue <= group.count)) {
        setUpdateCount(value);
      }
    };

    const handleUpdateCountFocus = () => {
      setUpdateCount('');
    };

    const handleUpdateCountBlur = () => {
      if (updateCount === '') {
        setUpdateCount('');
      } else {
        const numValue = parseInt(updateCount, 10);
        setUpdateCount(numValue.toString());
      }
    };

    const handleBulkUpdate = async () => {
      if (updateCount === '' || (groupStatus === '' && groupAssignee === '')) return;

      setIsUpdating(true);
      setUpdateDialogOpen(true);
      setOverallProgress(0);

      const tasksToUpdate = group.tasks.slice(0, parseInt(updateCount));
      setUpdatingStatus(prev => ({ ...prev, [group.id]: true }));
      setUpdateProgress(prev => ({ ...prev, [group.id]: 0 }));

      const updatedTasks = [];
      for (let i = 0; i < tasksToUpdate.length; i++) {
        const task = tasksToUpdate[i];
        try {
          if (groupStatus !== '') {
            await updateTaskStatus(task.TaskID, task.RequestID, groupStatus, user.EmailAddress);
          }
          if (groupAssignee !== '') {
            await updateTaskAssignee(task.TaskID, task.RequestID, groupAssignee, user.EmailAddress);
          }
          updatedTasks.push({ 
            ...task, 
            TaskStatus: groupStatus !== '' ? groupStatus : task.TaskStatus,
            AssignedTo: groupAssignee !== '' ? groupAssignee : task.AssignedTo
          });
          handleUpdateProgress(i + 1, tasksToUpdate.length);
        } catch (error) {
          console.error(`Error updating task ${task.TaskID}:`, error);
          updatedTasks.push(task);
        }
      }

      setTasks(prevTasks => 
        prevTasks.map(t => {
          const updatedTask = updatedTasks.find(ut => ut.TaskID === t.TaskID);
          return updatedTask || t;
        })
      );

      setUpdatingStatus(prev => ({ ...prev, [group.id]: false }));
      setUpdateCount('');
      setGroupStatus('');
      setGroupAssignee('');
      setIsUpdating(false);
      setUpdateDialogOpen(false);
    };

    return (
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between', 
            alignItems: isMobile ? 'flex-start' : 'center', 
            width: '100%' 
          }}>
            <Typography sx={{ mb: isMobile ? 1 : 0 }}>
              {`${group.projectName} - ${group.taskType} - ${group.building} - Floor ${group.floor} - `}
              <span style={{ color: getStatusColor(group.taskStatus) }}>{group.taskStatus}</span>
              {` (${group.count} tasks)`}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                type="text"
                inputMode="numeric"
                pattern="[1-9][0-9]*"
                value={updateCount}
                onChange={handleUpdateCountChange}
                onFocus={handleUpdateCountFocus}
                onBlur={handleUpdateCountBlur}
                placeholder={`1-${group.count}`}
                sx={{ width: 80, mr: 1 }}
                inputProps={{
                  style: { textAlign: 'center' }
                }}
              />
              <Select
                value={groupStatus}
                onChange={handleGroupStatusChange}
                displayEmpty
                onClick={(e) => e.stopPropagation()}
                disabled={isUpdating}
                sx={{ minWidth: 120, mr: 1 }}
              >
                <MenuItem value="" disabled>Update Status</MenuItem>
                {statusData.status.map((status) => (
                  <MenuItem key={status.id} value={status.name} style={{ color: status.color }}>
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
              <Select
                value={groupAssignee}
                onChange={handleGroupAssigneeChange}
                displayEmpty
                onClick={(e) => e.stopPropagation()}
                disabled={isUpdating}
                sx={{ minWidth: 120, mr: 1 }}
              >
                <MenuItem value="" disabled>Reassign</MenuItem>
                {group.taskMembers.map((member) => (
                  <MenuItem key={member.EmailAddress} value={member.EmailAddress}>
                    {`${member.FirstName} ${member.LastName}`}
                  </MenuItem>
                ))}
              </Select>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleBulkUpdate}
                disabled={(updateCount === '' || (groupStatus === '' && groupAssignee === '')) || isUpdating}
              >
                Update
              </Button>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer sx={{ overflowX: 'auto' }}>
            <Table size={isMobile ? "small" : "medium"}>
              <TableHead>
                <TableRow>
                  {['Task #', 'Description', 'Priority', 'Due Date', 'Assigned To', 'Status', 'Action'].map((header) => (
                    <TableCell key={header} sx={{ display: isMobile && header !== 'Task #' && header !== 'Action' ? 'none' : 'table-cell' }}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {group.tasks.map((task) => (
                  <TableRow key={task.TaskID}>
                    <TableCell>{task.TaskNumber}</TableCell>
                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{task.TaskDescription}</TableCell>
                    <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>{task.Priority}</TableCell>
                    <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>{task.DueDate}</TableCell>
                    <TableCell sx={{ display: { xs: 'none', lg: 'table-cell' } }}>{task.AssignedTo}</TableCell>
                    <TableCell style={{ color: getStatusColor(task.TaskStatus) }}>{task.TaskStatus}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        component={Link}
                        to={`/task/${task.TaskID}/${task.RequestID}`}
                      >
                        Go
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
        <Typography variant="h6" gutterBottom>My Tasks</Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search tasks by group name, floor, status..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mb: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Grid container spacing={2}>
          {filteredGroups.map((group) => (
            <Grid item xs={12} key={group.id}>
              <TaskGroup group={group} />
            </Grid>
          ))}
        </Grid>
        {filteredGroups.length === 0 && (
          <Typography>No matching tasks found</Typography>
        )}
      </Paper>
      <Dialog open={updateDialogOpen} onClose={() => setUpdateDialogOpen(false)}>
        <DialogTitle>Updating Tasks</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress variant="determinate" value={overallProgress} />
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
              {`${Math.round(overallProgress)}%`}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateDialogOpen(false)} color="primary" disabled={isUpdating}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserTasks;