import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk';
import {
  Card, CardContent, Typography, Select, MenuItem, TextField,
  Button, Grid, CircularProgress, Box, Container, Stack
} from '@mui/material';
import {
  updateConfigItem, fetchUsers, fetchIncident, updateStatus,
  updatePriority, updateAssignee, getAttachmentIDs,
} from '../../ApiServices/ApiServices';
import NotesForm from '../Components/NotesForm/NotesForm';
import UploadButton from '../Components/UploadButton/UploadButton';
import statusData from '../Data/status.json';
import priority from '../Data/priority.json';

const Incident = () => {
  const { incidentID } = useParams();
  const [incident, setIncident] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentUser, setUser] = useState('');
  const [userInfo, setUsers] = useState();
  const [selectedPriority, setSelectedPriority] = useState('');
  const [selectedAssignee, setSelectedAssignee] = useState('');
  const [life, setLife] = useState([]);
  const [configItem, setConfigItem] = useState('');
  const [tempConfigItem, setTempConfigItem] = useState('');
  const [imageUrls, setImageUrls] = useState([]);

  const licenseKey = '8650c58b40f5c8d3e3325c7ce4c3e6e2cb88ba80b9f4d544dcc0dfae76acd8c23797d5ceada346b74919ef9392ab5d410122d067defacec4a0545316f8c57553';
  const bucketName = 'ciservicedesk';
  const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const region = process.env.REACT_APP_AWS_REGION;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        setUser(idToken.payload.email);
        const usersResponse = await fetchUsers(licenseKey);
        setUsers(usersResponse);
        const incidentResponse = await fetchIncident(incidentID);
        setIncident(incidentResponse);
        const history = JSON.parse(incidentResponse?.record?.Lifespan);
        setLife(history.status);
        setSelectedStatus(incidentResponse?.record?.IncidentStatus || '');
        setSelectedPriority(incidentResponse?.record?.Priority || '');
        setSelectedAssignee(incidentResponse?.record?.AssignedTo ?? '');
        setConfigItem(incidentResponse?.record?.ConfigurationItem || '');
        setStatusOptions(statusData.status);
        
        const attachmentIDs = await getAttachmentIDs(incidentID);
        const fetchedImageUrls = await fetchImagesFromS3(attachmentIDs);
        setImageUrls(fetchedImageUrls);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [incidentID]);

  const fetchImagesFromS3 = async (attachmentIDs) => {
    const s3 = new AWS.S3({
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      region: region,
    });
    
    const urls = [];
    for (const attachmentID of attachmentIDs) {
      const response = await s3.listObjectsV2({ Bucket: bucketName, Prefix: attachmentID }).promise();
      const imageKeys = response.Contents
        .map(object => object.Key)
        .filter(key => key.match(/\.(jpeg|jpg|png|gif|heic)$/));
      urls.push(...imageKeys);
    }
    return urls;
  };

  const handleStatusChange = async (newValue) => {
    setSelectedStatus(newValue);
    try {
      await updateStatus(incidentID, newValue, currentUser);
      alert('Incident Status Updated Successfully');
    } catch (error) {
      console.error('Error Updating Status:', error);
    }
  };

  const handlePriorityChange = async (newValue) => {
    setSelectedPriority(newValue);
    try {
      await updatePriority(incidentID, newValue, currentUser);
      alert('Incident Priority Updated Successfully');
    } catch (error) {
      console.error('Error Updating Priority:', error);
    }
  };

  const handleAssignmentChange = async (newValue) => {
    setSelectedAssignee(newValue);
    try {
      await updateAssignee(incidentID, newValue, currentUser);
      alert('Assignee Updated');
    } catch (error) {
      console.error('Error Updating Assignee:', error);
    }
  };

  const handleCIChange = async () => {
    try {
      await updateConfigItem(incidentID, tempConfigItem);
      alert('Configuration Item updated Successfully');
      setConfigItem(tempConfigItem);
      setTempConfigItem('');
    } catch (error) {
      console.error('Error Updating Configuration Item:', error);
    }
  };

  const getStatusColor = (statusText) => {
    const statusObject = statusData.status.find(item => item.name === statusText);
    return statusObject ? statusObject.color : 'white';
  };

  const getPriorityColor = (priorityText) => {
    const priorityObject = priority.find(item => item.priority === priorityText);
    return priorityObject ? priorityObject.color : 'white';
  };

  const statusColor = useMemo(() => getStatusColor(selectedStatus), [selectedStatus]);
  const priorityColor = useMemo(() => getPriorityColor(selectedPriority), [selectedPriority]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ pt: 3}}>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h6">Ticket Details</Typography>
                <Typography color="primary">{incident?.record?.IncidentType}</Typography>
                <Typography>{incident?.record?.IncidentDescription}</Typography>
                <Typography>Incident: {incident?.record?.IncidentNumber || ''}</Typography>
                <Typography>Organization: {incident?.record?.Organization || 'NA'}</Typography>
                <Typography>Location: {incident?.record?.Location || 'NA'}</Typography>
                <Typography>Created: {incident?.record?.CreationTime || ''}</Typography>
                <Select
                  value={selectedStatus}
                  onChange={(e) => handleStatusChange(e.target.value)}
                  style={{ borderColor: statusColor, color: statusColor }}
                  fullWidth
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.name} value={option.name}>{option.name}</MenuItem>
                  ))}
                </Select>
                <Select
                  value={selectedPriority}
                  onChange={(e) => handlePriorityChange(e.target.value)}
                  style={{ borderColor: priorityColor, color: priorityColor }}
                  fullWidth
                >
                  {priority.map((option) => (
                    <MenuItem key={option.priority} value={option.priority}>{option.priority}</MenuItem>
                  ))}
                </Select>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h6">Assigned To</Typography>
                <Typography>Assignment Group: {incident?.record?.AssignedGroup}</Typography>
                <Select
                  value={selectedAssignee}
                  onChange={(e) => handleAssignmentChange(e.target.value)}
                  fullWidth
                >
                  {userInfo?.response?.map((option) => (
                    <MenuItem key={option.EmailAddress} value={option.EmailAddress}>
                      {option.EmailAddress}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h6">Requester Detail</Typography>
                <Typography>{incident?.record?.RequesterName || ''}</Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h6">Incident Log</Typography>
                {incident && <NotesForm userEmail={currentUser} incident={incident} />}
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h6">Incident Lifecycle</Typography>
                {life.map((entry, index) => (
                  <Typography key={index}>
                    <strong>{entry.status}</strong> - {entry.timestamp}: {entry.user}
                  </Typography>
                ))}
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h6">Optional Fields</Typography>
                <TextField
                  label="Configuration Item"
                  value={tempConfigItem}
                  onChange={(e) => setTempConfigItem(e.target.value)}
                  fullWidth
                />
                <Button onClick={handleCIChange} variant="contained">Update Config Item</Button>
                <Typography>Current Item: {configItem}</Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h6">Upload Pictures</Typography>
                <UploadButton incidentID={incidentID} />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  {imageUrls.map((imageUrl, index) => (
                    <img
                      key={index}
                      src={`https://${bucketName}.s3.${region}.amazonaws.com/${imageUrl}`}
                      alt={`Image ${index}`}
                      style={{ width: '20%', height: 'auto', cursor: 'pointer' }}
                      onClick={() => window.open(`https://${bucketName}.s3.${region}.amazonaws.com/${imageUrl}`, '_blank')}
                    />
                  ))}
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Incident;