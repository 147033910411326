import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Autocomplete,
  Backdrop,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import { fetchAllAssignmentGroups, fetchUsers, updateAssignmentGroupMembers } from "../../ApiServices/ApiServices";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2),
}));

const AssignmentGroupDetails = () => {
  const { groupName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [groupDetails, setGroupDetails] = useState(location.state?.groupDetails || null);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);

  const licenseKey = "8650c58b40f5c8d3e3325c7ce4c3e6e2cb88ba80b9f4d544dcc0dfae76acd8c23797d5ceada346b74919ef9392ab5d410122d067defacec4a0545316f8c57553";

  const fetchGroupDetails = useCallback(async () => {
    setLoading(true);
    try {
      const groups = await fetchAllAssignmentGroups();
      const groupData = groups[groupName];
      if (groupData) {
        setGroupDetails({
          ...groupData,
          members: Array.isArray(groupData.members) 
            ? groupData.members 
            : groupData.members 
              ? JSON.parse(groupData.members) 
              : []
        });
      } else {
        console.error("Group not found");
        navigate("/assignmentGroups");
      }
    } catch (error) {
      console.error("Error fetching group details:", error);
    }
    setLoading(false);
  }, [groupName, navigate]);

  const fetchAllUsers = useCallback(async () => {
    setLoadingUsers(true);
    try {
      const usersData = await fetchUsers(licenseKey);
      setAllUsers(usersData.response || []);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    setLoadingUsers(false);
  }, []);

  useEffect(() => {
    if (!groupDetails) {
      fetchGroupDetails();
    } else {
      setLoading(false);
    }
    fetchAllUsers();
  }, [groupDetails, fetchGroupDetails, fetchAllUsers]);

  const handleAddMember = async () => {
    if (selectedUser) {
      setApiLoading(true);
      const updatedMembers = [...(groupDetails.members || []), selectedUser.EmailAddress];
      try {
        const response = await updateAssignmentGroupMembers(groupName, updatedMembers);
        console.log("Add member response:", response);
        
        // The response should now directly contain the updated members array
        const newMembers = response.members || updatedMembers;
  
        setGroupDetails(prev => ({
          ...prev,
          members: newMembers,
          totalMembers: newMembers.length
        }));
        setSelectedUser(null);
      } catch (error) {
        console.error("Error adding member:", error);
      }
      setApiLoading(false);
    }
  };

  const handleRemoveMember = async (emailToRemove) => {
    setApiLoading(true);
    const updatedMembers = (groupDetails.members || []).filter(email => email !== emailToRemove);
    try {
      const response = await updateAssignmentGroupMembers(groupName, updatedMembers);
      console.log("Remove member response:", response);
      
      // The response should now directly contain the updated members array
      const newMembers = response.members || updatedMembers;
  
      setGroupDetails(prev => ({
        ...prev,
        members: newMembers,
        totalMembers: newMembers.length
      }));
    } catch (error) {
      console.error("Error removing member:", error);
    }
    setApiLoading(false);
  };

  if (loading || loadingUsers) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!groupDetails) {
    return null;
  }

  const groupMembers = (groupDetails.members || []).map(email => 
    allUsers.find(user => user.EmailAddress === email) || { EmailAddress: email }
  );

  const availableUsers = allUsers.filter(user => 
    !groupDetails.members.includes(user.EmailAddress)
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={apiLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <IconButton onClick={() => navigate("/assignmentGroups")} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">
          Assignment Group: {groupName}
        </Typography>
      </Box>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>Group Details</Typography>
        <Typography><strong>Project Name:</strong> {groupDetails.projectName}</Typography>
        <Typography><strong>Service Name:</strong> {groupDetails.serviceName}</Typography>
        <Typography><strong>Task Name:</strong> {groupDetails.taskName}</Typography>
        <Typography><strong>Total Members:</strong> {groupDetails.totalMembers}</Typography>
      </Paper>

      <Box sx={{ display: "flex", alignItems: "flex-end", mb: 2 }}>
        <Autocomplete
          options={availableUsers}
          getOptionLabel={(option) => `${option.FirstName} ${option.LastName} (${option.EmailAddress})`}
          value={selectedUser}
          onChange={(event, newValue) => {
            setSelectedUser(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="Select User" variant="standard" sx={{ width: 300, mr: 2 }} />}
        />
        <Button
          variant="contained"
          startIcon={<PersonAddIcon />}
          onClick={handleAddMember}
          disabled={!selectedUser || apiLoading}
        >
          Add Member
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupMembers.length > 0 ? (
              groupMembers.map((user) => (
                <TableRow key={user.EmailAddress}>
                  <TableCell>{user.FirstName || 'N/A'}</TableCell>
                  <TableCell>{user.LastName || 'N/A'}</TableCell>
                  <TableCell>{user.EmailAddress}</TableCell>
                  <TableCell>{user.Phone || 'N/A'}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Remove Member">
                      <IconButton 
                        onClick={() => handleRemoveMember(user.EmailAddress)} 
                        color="error"
                        disabled={apiLoading}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>No members found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AssignmentGroupDetails;