import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Grid } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import statusData from '../../Data/srStatus.json';

const StatusChart = ({ data, title }) => {
  // Calculate the total
  const total = data.reduce((sum, item) => sum + item.value, 0);

  // Filter out zero values for the pie chart
  const pieChartData = data.filter(item => item.value > 0);

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" align="center" gutterBottom>{title}</Typography>
        <Typography variant="h5" align="center" gutterBottom>Total: {total}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <PieChart
            series={[
              {
                data: pieChartData,
                innerRadius: 80,
                outerRadius: 120,
                cornerRadius: 4,
                paddingAngle: 2,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 10, additionalRadius: -30 },
              },
            ]}
            height={300}
            width={300}
            margin={{ right: 5, left: 5, top: 5, bottom: 5 }}
            legend={{ hidden: true }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
          {data.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: item.color,
                  mr: 1,
                }}
              />
              <Typography variant="body2">
                {item.label}: {item.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

const MultiStatusChart = ({ services }) => {
  const [serviceTypeStatusData, setServiceTypeStatusData] = useState({});

  useEffect(() => {
    const processData = () => {
      const serviceTypes = [...new Set(services.map(service => service.RequestType))];
      const newServiceTypeStatusData = {};

      serviceTypes.forEach(serviceType => {
        const serviceTypeServices = services.filter(service => service.RequestType === serviceType);
        const statusCounts = serviceTypeServices.reduce((acc, service) => {
          acc[service.ServiceStatus] = (acc[service.ServiceStatus] || 0) + 1;
          return acc;
        }, {});

        const chartData = statusData.status.map(status => ({
          value: statusCounts[status.name] || 0,
          label: status.name,
          color: status.color,
        }));

        newServiceTypeStatusData[serviceType] = chartData;
      });

      setServiceTypeStatusData(newServiceTypeStatusData);
    };

    processData();
  }, [services]);

  return (
    <Box>
      <Typography variant="h5" align="center" gutterBottom>Individual Service Status</Typography>
      <Grid container spacing={3}>
        {Object.entries(serviceTypeStatusData).map(([serviceType, statusData]) => (
          <Grid item xs={12} md={6} lg={4} key={serviceType}>
            <StatusChart data={statusData} title={serviceType} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MultiStatusChart;