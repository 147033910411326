import React from "react";
import { Card, CardContent, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, IconButton, Chip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const ServiceDefinitionsSection = ({ projectData, setProjectData }) => {
  const handleRemoveServiceDefinition = (index) => {
    setProjectData(prevData => ({
      ...prevData,
      completeServiceDefinitions: prevData.completeServiceDefinitions.filter((_, i) => i !== index)
    }));
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Complete Service Definitions
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>Building Name</TableCell>
                <TableCell>Floor</TableCell>
                <TableCell>Service Count</TableCell>
                <TableCell>Groups</TableCell>
                <TableCell>Tickets Created</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectData.completeServiceDefinitions.map((def, index) => (
                <TableRow key={index}>
                  <TableCell>{def.service}</TableCell>
                  <TableCell>{def.building}</TableCell>
                  <TableCell>{def.floor}</TableCell>
                  <TableCell>{def.count}</TableCell>
                  <TableCell>{def.groups.length}</TableCell>
                  <TableCell>
                    <Chip
                      label={def.deployed ? "Created" : "Not Created"}
                      color={def.deployed ? "success" : "error"}
                      icon={def.deployed ? <CheckCircleIcon /> : <ErrorIcon />}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleRemoveServiceDefinition(index)}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default ServiceDefinitionsSection;