import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  CircularProgress
} from '@mui/material';
import { fetchTasks } from "../../../ApiServices/ApiServices";

const TaskList = ({ requestID, isOpen, onCompletionPercentageChange }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const getTasks = async () => {
      if (!isOpen) return;

      try {
        setLoading(true);
        console.log("Getting Tasks for RequestID:", requestID);
        const data = await fetchTasks(requestID);
        console.log("Tasks Retrieved:", data.body);
        if (isMounted) {
          // Sort tasks by Level in ascending order
          const sortedTasks = (data.body || []).sort((a, b) => a.Level - b.Level);
          setTasks(sortedTasks);
          setLoading(false);

          // Calculate completion percentage
          const totalTasks = sortedTasks.length;
          const completedTasks = sortedTasks.filter(task => task.TaskStatus === "Completed").length;
          const completionPercentage = totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
          onCompletionPercentageChange(completionPercentage);
        }
      } catch (error) {
        console.error("Error fetching Tasks:", error);
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    getTasks();

    return () => {
      isMounted = false;
    };
  }, [requestID, isOpen, onCompletionPercentageChange]);

  const handleGoToTask = (taskID) => {
    navigate(`/task/${taskID}/${requestID}`);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (tasks.length === 0) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography>No tasks found for this request.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Tasks
      </Typography>
      <Table size="small" aria-label="tasks">
        <TableHead>
          <TableRow>
            <TableCell>Number</TableCell>
            <TableCell>Level</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Assignment Group</TableCell>
            <TableCell>Priority</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map((task) => (
            <TableRow key={task.TaskID}>
              <TableCell component="th" scope="row">
                {task.TaskNumber}
              </TableCell>
              <TableCell>{task.Level}</TableCell>
              <TableCell>{task.TaskType}</TableCell>
              <TableCell>{task.TaskStatus}</TableCell>
              <TableCell>{task.AssignmentGroup}</TableCell>
              <TableCell>{task.Priority}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleGoToTask(task.TaskID)}
                >
                  Go
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TaskList;