import React, { useEffect, useState } from 'react';
import { useUser } from '../../../UserContext';
import { Box, Typography, Grid, Paper, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SmallPieChart from './SmallPieChart';
import { getAssignedIncidents, getAssignedServices, getUserTasks, getUserProjects } from '../../ApiServices/ApiServices';
import statusData from '../Data/srStatus.json';

const DASHBOARD_ITEMS = {
  openIncidents: { title: 'Incidents', route: '/userIncidents' },
  openServices: { title: 'Services', route: '/userServices' },
  pendingTasks: { title: 'Tasks', route: '/userTasks' },
  activeProjects: { title: 'Projects', route: '/projects' }
};

const Dashboard = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [userStats, setUserStats] = useState({
    openIncidents: { total: 0, data: {} },
    openServices: { total: 0, data: {} },
    pendingTasks: { total: 0, data: {} },
    activeProjects: { total: 0, data: {} }
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const statusColors = Object.fromEntries(
    statusData.status.map(status => [status.name, status.color])
  );

  useEffect(() => {
    const fetchUserStats = async () => {
      if (user && user.EmailAddress) {
        try {
          setIsLoading(true);
          setError(null);

          const incidentResponse = await getAssignedIncidents(user.EmailAddress);
          const incidentsData = JSON.parse(incidentResponse.body);

          const serviceResponse = await getAssignedServices(user.EmailAddress);
          const servicesData = JSON.parse(serviceResponse.body);
          console.log("ServicesData for DashBoard:", servicesData);
          const tasksResponse = await getUserTasks(user.EmailAddress);
          const tasksData = tasksResponse.body;

          const projectsResponse = await getUserProjects(user.EmailAddress);
          const projectsData = JSON.parse(projectsResponse.body);

          if (!Array.isArray(incidentsData) || !Array.isArray(servicesData) || !Array.isArray(tasksData) || !Array.isArray(projectsData)) {
            throw new Error("Data is not in the expected format");
          }

          const processData = (data, statusField) => {
            const statusCounts = data.reduce((acc, item) => {
              const status = item[statusField] || 'Unknown';
              acc[status] = (acc[status] || 0) + 1;
              return acc;
            }, {});

            return {
              total: data.length,
              data: statusCounts
            };
          };

          setUserStats({
            openIncidents: processData(incidentsData, 'IncidentStatus'),
            openServices: processData(servicesData, 'ServiceStatus'),
            pendingTasks: processData(tasksData, 'TaskStatus'),
            activeProjects: processData(projectsData, 'ProjectStatus')
          });

        } catch (error) {
          console.error("Error fetching user stats:", error);
          setError("Failed to fetch dashboard data. Please try again later.");
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchUserStats();
  }, [user]);

  const handleNavigation = (route) => {
    navigate(route);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Welcome, {user?.FirstName || 'Guest'}!
      </Typography>
      <Grid container spacing={3}>
        {Object.entries(userStats).map(([key, value]) => (
          <Grid item xs={12} sm={6} md={3} key={key}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
              <SmallPieChart
                data={Object.entries(value.data).reduce((acc, [status, count]) => {
                  acc[status] = { count, color: statusColors[status] || '#999999' };
                  return acc;
                }, {})}
                title={DASHBOARD_ITEMS[key].title}
                count={value.total}
              />
              <Button onClick={() => handleNavigation(DASHBOARD_ITEMS[key].route)}>
                View {DASHBOARD_ITEMS[key].title}
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
      
      {/* Status Legend */}
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>Status Legend</Typography>
        <Grid container spacing={2}>
          {statusData.status.map((status) => (
            <Grid item key={status.id}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    backgroundColor: status.color,
                    mr: 1,
                  }}
                />
                <Typography variant="body2">{status.name}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {user?.AccessRights?.includes('SuperUser') && (
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>Admin Quick Actions</Typography>
          <Button variant="contained" onClick={() => handleNavigation('/accessRights')} sx={{ mr: 2 }}>
            Manage Access Rights
          </Button>
          {/* <Button variant="contained" onClick={() => handleNavigation('/assignmentGroups')} sx={{ mr: 2 }}>
            Manage Assignment Groups
          </Button> */}
          <Button variant="contained" onClick={() => handleNavigation('/projectCreation')}>
            Create New Project
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;