import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAssignedServices } from '../../ApiServices/ApiServices';
import { useUser } from '../../../UserContext';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TableSortLabel, Toolbar, Typography, Paper, IconButton, Tooltip, FormControlLabel,
  Switch, Button, CircularProgress, TextField, InputAdornment
} from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import SearchIcon from "@mui/icons-material/Search";
import statusData from '../Data/srStatus.json';
import priorityData from '../Data/priority.json';

export default function UserServices() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const navigate = useNavigate();
  const { user } = useUser();
  const [services, setServices] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('RequestNumber');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const headCells = useMemo(() => [
    { id: 'RequestNumber', numeric: false, disablePadding: false, label: 'Request #' },
    { id: 'RequestType', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'RequestDescription', numeric: false, disablePadding: false, label: 'Description', hideOnMobile: true, hideOnTablet: true },
    { id: 'CreationTime', numeric: false, disablePadding: false, label: 'Created', hideOnMobile: true },
    { id: 'Priority', numeric: false, disablePadding: false, label: 'Priority', hideOnMobile: true },
    { id: 'ServiceStatus', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'Action', numeric: false, disablePadding: false, label: 'Action' }
  ], []);

  const getStatusColor = (statusName) => {
    const status = statusData.status.find(s => s.name === statusName);
    return status ? status.color : theme.palette.text.primary;
  };
  
  const getPriorityColor = (priorityName) => {
    const priority = priorityData.find(p => p.priority === priorityName);
    return priority ? priority.color : theme.palette.text.primary;
  };

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        if (user && user.EmailAddress) {
          const data = await getAssignedServices(user.EmailAddress);
          const parsedServiceData = JSON.parse(data.body);
          console.log("Data Response Services", parsedServiceData);
          setServices(parsedServiceData);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching assigned services:", error);
      }
    };

    fetchServiceData();
  }, [user]);

  const filteredServices = useMemo(() => {
    return services.filter(service =>
      Object.entries(service).some(
        ([key, value]) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [services, searchTerm]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            ((!headCell.hideOnMobile || !isMobile) && (!headCell.hideOnTablet || !isTablet)) && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{ 
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  px: 1,
                  py: 1,
                  fontSize: isMobile ? '0.75rem' : '0.875rem'
                }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function EnhancedTableToolbar() {
    return (
      <Toolbar>
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          My Assigned Services
        </Typography>

        <Tooltip title="Export All">
          <IconButton onClick={() => console.log("Export functionality to be implemented")}>
            <FileDownloadOutlined />
          </IconButton>
        </Tooltip>
      </Toolbar>
    );
  }

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredServices, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, filteredServices],
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, boxShadow: 'none' }}>
        <EnhancedTableToolbar />
        <Box sx={{ p: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search services..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <TableContainer sx={{ overflowX: 'auto', overflowY: 'visible', width: '100vw', maxWidth: '100%' }}>
          <Table
            sx={{ 
              width: '100%', 
              tableLayout: 'fixed',
              '& .MuiTableCell-root': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                wordWrap: 'break-word'
              }
            }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {visibleRows.map((row) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.RequestID}
                  >
                    <TableCell component="th" scope="row" sx={{ width: isMobile ? '20%' : '10%', p: 1 }}>
                      {row.RequestNumber}
                    </TableCell>
                    <TableCell align="left" sx={{ width: isMobile ? '20%' : '10%', p: 1 }}>
                      {row.RequestType}
                    </TableCell>
                    {!isMobile && !isTablet && (
                      <TableCell align="left" sx={{ width: '25%', p: 1 }}>{row.RequestDescription}</TableCell>
                    )}
                    {!isMobile && (
                      <>
                        <TableCell align="left" sx={{ width: '15%', p: 1 }}>{row.CreationTime}</TableCell>
                        <TableCell align="left" sx={{ width: '10%', p: 1 }}>
                          <span style={{ color: getPriorityColor(row.Priority) }}>
                            {row.Priority}
                          </span>
                        </TableCell>
                      </>
                    )}
                    <TableCell align="left" sx={{ width: isMobile ? '30%' : '15%', p: 1 }}>
                      <span style={{ color: getStatusColor(row.ServiceStatus) }}>
                        {row.ServiceStatus}
                      </span>
                    </TableCell>
                    <TableCell align="left" sx={{ width: isMobile ? '30%' : '15%', p: 1 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/service/${row.RequestID}`}
                        size="small"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={filteredServices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Compact view"
      />
    </Box>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}