import React from 'react';
import { Box, Typography, Card, CardContent, Grid, useTheme, useMediaQuery } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import statusData from '../../Data/srStatus.json';

const TaskGroupChart = ({ taskType, taskData }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));

  const { statusCounts, totalTasks, level } = taskData;

  const chartData = Object.entries(statusCounts)
    .filter(([_, count]) => count > 0)
    .map(([status, count]) => ({
      value: count,
      label: status,
      color: statusData.status.find(s => s.name === status)?.color || theme.palette.grey[500]
    }));

  const chartSize = isXs ? 150 : isSm ? 180 : 200;

  // Calculate completion percentage
  const completedTasks = statusCounts['Completed'] || 0;
  const completionPercentage = totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle2" gutterBottom>{taskType}</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <PieChart
              series={[
                {
                  data: chartData,
                  innerRadius: chartSize * 0.3,
                  outerRadius: chartSize * 0.4,
                  paddingAngle: 2,
                  cornerRadius: 4,
                  startAngle: -90,
                  endAngle: 270,
                },
              ]}
              height={chartSize}
              width={chartSize}
              legend={{ hidden: true }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Typography variant="body1" align="center" gutterBottom>
            Level: {level}
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            Total Tasks: {totalTasks}
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            {`${Math.round(completionPercentage)}% Complete`}
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2 }}>
            {statusData.status.map((status, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    backgroundColor: status.color,
                    mr: 1,
                  }}
                />
                <Typography variant="body2">
                  {`${status.name}: ${statusCounts[status.name] || 0}`}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const ServiceTypeSection = ({ serviceType, taskGroups }) => {
  // Sort task groups by level
  const sortedTaskGroups = Object.entries(taskGroups).sort((a, b) => {
    const levelA = parseInt(a[1].level);
    const levelB = parseInt(b[1].level);
    return levelA - levelB;
  });

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>{serviceType}</Typography>
      {sortedTaskGroups.map(([taskKey, taskData]) => (
        <TaskGroupChart key={taskKey} taskType={taskData.taskType} taskData={taskData} />
      ))}
    </Box>
  );
};

const ServiceStatusCharts = ({ serviceTypeLevelsData }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>Service Type Progress</Typography>
        {Object.entries(serviceTypeLevelsData).map(([serviceType, taskGroups]) => (
          <ServiceTypeSection key={serviceType} serviceType={serviceType} taskGroups={taskGroups} />
        ))}
      </CardContent>
    </Card>
  );
};

export default ServiceStatusCharts;