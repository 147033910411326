import React, { useState } from "react";
import { Box, Button, CircularProgress, Dialog, DialogContent, Typography, LinearProgress } from "@mui/material";

const ProjectActions = ({ isEditMode, handleSaveProject, handleSubmitProject }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCreatingTickets, setIsCreatingTickets] = useState(false);
  const [ticketProgress, setTicketProgress] = useState(0);
  const [currentTicketIndex, setCurrentTicketIndex] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);

  const onSaveProject = async () => {
    setIsSaving(true);
    try {
      await handleSaveProject();
    } catch (error) {
      console.error("Error saving project:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const onSubmitProject = async () => {
    setIsSubmitting(true);
    setIsCreatingTickets(true);
    setTicketProgress(0);
    setCurrentTicketIndex(0);

    try {
      await handleSubmitProject((current, total) => {
        setCurrentTicketIndex(current);
        setTotalTickets(total);
        setTicketProgress((current / total) * 100);
      });
    } catch (error) {
      console.error("Error submitting project:", error);
    } finally {
      setIsSubmitting(false);
      setIsCreatingTickets(false);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 3, gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSaveProject}
          disabled={isSaving}
        >
          {isEditMode ? "Update Project" : "Save Project"}
        </Button>
        {isSaving && <CircularProgress size={24} />}

        <Button
          variant="contained"
          color="secondary"
          onClick={onSubmitProject}
          disabled={isSubmitting || !isEditMode}
        >
          Submit Project
        </Button>
        {isSubmitting && <CircularProgress size={24} />}
      </Box>

      <Dialog open={isCreatingTickets} fullWidth maxWidth="sm">
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Creating Project Tickets
          </Typography>
          <Typography variant="body2" gutterBottom>
            Creating ticket {currentTicketIndex} of {totalTickets}
          </Typography>
          <Box sx={{ width: "100%", mt: 2 }}>
            <LinearProgress variant="determinate" value={ticketProgress} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
            <Typography variant="body2" color="text.secondary">
              {`${Math.round(ticketProgress)}%`}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProjectActions;