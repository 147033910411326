import React, { useState, useEffect } from 'react';
import {
    TextField,
    Autocomplete,
    Box,
    Typography,
    Card,
    CardContent,
    Grid,
    Avatar,
    Chip,
    useTheme,
    CircularProgress,
    IconButton,
    Tooltip
} from '@mui/material';
import { Star, StarBorder, AddCircleOutline, EventAvailable } from '@mui/icons-material';
import { motion } from 'framer-motion';

import services from '../Data/servicesDatabase.json';
import technicians from '../Data/Technicians.json';
import skillsMatrix from '../Data/SkillsMatrix.json';

const ControlTower = () => {
    const [selectedService, setSelectedService] = useState(null);
    const [rankedTechnicians, setRankedTechnicians] = useState([]);
    const theme = useTheme();

    // Flatten all services from all categories
    const allServices = services.response.flatMap(category =>
        Object.entries(category).flatMap(([_, services]) => services)
    );

    useEffect(() => {
        if (selectedService) {
            const serviceSkills = skillsMatrix.serviceSkills[selectedService.serviceID].Skills;
            const rankedTechs = technicians.technicians
                .map(tech => {
                    const skillScore = serviceSkills.reduce((sum, skill) => sum + tech.skills[skill], 0) / serviceSkills.length;
                    const utilizationScore = 100 - tech.utilization;
                    const ratingScore = tech.rating * 20;
                    const overallScore = (skillScore + utilizationScore + ratingScore) / 3;
                    return { ...tech, overallScore, recommendationRating: Math.round(overallScore) };
                })
                .sort((a, b) => b.overallScore - a.overallScore);
            setRankedTechnicians(rankedTechs);
        }
    }, [selectedService]);

    const renderStars = (rating) => {
        return [...Array(5)].map((_, index) => (
            index < rating ? <Star key={index} color="primary" /> : <StarBorder key={index} color="primary" />
        ));
    };

    const getCardColor = (index) => {
        const hue = (index / (rankedTechnicians.length - 1)) * 240;
        return theme.palette.mode === 'light'
            ? `hsla(${hue}, 60%, 95%, 0.2)`
            : `hsla(${hue}, 60%, 25%, 0.2)`;
    };

    const getTextColor = (index) => {
        const hue = (index / (rankedTechnicians.length - 1)) * 240;
        return theme.palette.mode === 'light'
            ? `hsl(${hue}, 60%, 30%)`
            : `hsl(${hue}, 60%, 70%)`;
    };

    const addToProject = (techId) => {
        console.log(`Add technician ${techId} to project`);
    };

    const showSchedule = (techId) => {
        console.log(`Show schedule for technician ${techId}`);
    };

    return (
        <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
            <Autocomplete
                options={allServices}
                getOptionLabel={(option) => option['Service Type']}
                renderInput={(params) => <TextField {...params} label="Select a Service" />}
                onChange={(event, newValue) => {
                    setSelectedService(newValue);
                }}
                sx={{ marginBottom: 2 }}
            />

            {rankedTechnicians.map((tech, index) => (
                <motion.div
                    key={tech.id}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                    <Card sx={{
                        marginBottom: 2,
                        backgroundColor: getCardColor(index),
                        color: getTextColor(index)
                    }}>
                        <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Avatar sx={{ width: 60, height: 60, fontSize: '1.5rem', bgcolor: getTextColor(index) }}>
                                        {tech.name.split(' ').map(n => n[0]).join('')}
                                    </Avatar>
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="h6">{tech.name}</Typography>
                                    <Box>{renderStars(tech.rating)}</Box>
                                    <Typography variant="body2">
                                        Utilization: {tech.utilization}%
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Add to Project">
                                        <IconButton
                                            onClick={() => addToProject(tech.id)}
                                            sx={{ width: 80, height: 80, color: getTextColor(index) }}
                                        >
                                            <AddCircleOutline sx={{ fontSize: 40 }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Show Schedule">
                                        <IconButton
                                            onClick={() => showSchedule(tech.id)}
                                            sx={{ width: 80, height: 80, color: getTextColor(index) }}
                                        >
                                            <EventAvailable sx={{ fontSize: 40 }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Box sx={{ position: 'relative', display: 'inline-flex', width: 80, height: 80 }}>
                                        <CircularProgress
                                            variant="determinate"
                                            value={tech.recommendationRating}
                                            size={80}
                                            thickness={4}
                                        />
                                        <Box
                                            sx={{
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                position: 'absolute',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography variant="h6" component="div" color="text.secondary">
                                                {`${tech.recommendationRating}%`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                                        {selectedService && skillsMatrix.serviceSkills[selectedService.serviceID]?.Skills.map(skill => (
                                            <Chip
                                                key={skill}
                                                label={`${skill}: ${tech.skills[skill]}`}
                                                size="small"
                                                sx={{
                                                    backgroundColor: `${getCardColor(index)}`,
                                                    color: getTextColor(index),
                                                    fontWeight: 'bold',
                                                    '& .MuiChip-label': {
                                                        color: getTextColor(index),
                                                    }
                                                }}
                                            />
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </motion.div> 
            ))}
        </Box>
    );
};

export default ControlTower;