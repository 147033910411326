import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  LinearProgress,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  DialogActions,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SaveIcon from "@mui/icons-material/Save";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useNavigate } from 'react-router-dom';

// Import the actual JSON catalog
import catalogData from "../Data/servicesDatabase.json";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2),
}));

const StyledTextField = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
});

const DescriptionTextField = styled(StyledTextField)({
  "& .MuiInputBase-input": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const ServicesCatalog = () => {
  const [catalog, setCatalog] = useState({});
  const [editedServices, setEditedServices] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [savingProgress, setSavingProgress] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [newService, setNewService] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState({
    "Service Type": true,
    Description: true,
    "List Price": true,
    "Misc Expenses": true,
    "Time Per": true,
  });
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);

  const navigate = useNavigate();
  
  useEffect(() => {
    setLoading(true);
    setCatalog(catalogData.response[0]);
    setLoading(false);
  }, []);

  const handleEdit = (categoryName, serviceId, field, value) => {
    setEditedServices((prev) => ({
      ...prev,
      [categoryName]: {
        ...(prev[categoryName] || {}),
        [serviceId]: {
          ...(prev[categoryName]?.[serviceId] || {}),
          [field]: value,
        },
      },
    }));
  };

  const handleSave = async (categoryName, serviceId) => {
    setSaving(true);
    setSavingProgress(0);

    // Simulate saving process
    for (let i = 0; i <= 100; i += 10) {
      setSavingProgress(i);
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    setCatalog((prevCatalog) => ({
      ...prevCatalog,
      [categoryName]: prevCatalog[categoryName].map((service) =>
        service.serviceID === serviceId
          ? { ...service, ...editedServices[categoryName][serviceId] }
          : service
      ),
    }));

    setEditedServices((prev) => {
      const newEdited = { ...prev };
      if (newEdited[categoryName]) {
        delete newEdited[categoryName][serviceId];
        if (Object.keys(newEdited[categoryName]).length === 0) {
          delete newEdited[categoryName];
        }
      }
      return newEdited;
    });

    setSaving(false);
  };

  const handleAddNewService = (categoryName) => {
    setNewService({
      categoryName,
      serviceID: Date.now().toString(),
      "Service Type": "",
      Description: "",
      "List Price": "",
      "Misc Expenses": "",
      "Time Per": "",
    });
  };

  const handleSaveNewService = async () => {
    setSaving(true);
    setSavingProgress(0);

    // Simulate saving process
    for (let i = 0; i <= 100; i += 10) {
      setSavingProgress(i);
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    setCatalog((prevCatalog) => ({
      ...prevCatalog,
      [newService.categoryName]: [
        ...prevCatalog[newService.categoryName],
        newService,
      ],
    }));
    setNewService(null);
    setSaving(false);
  };

  const handleDeleteService = (categoryName, service) => {
    setServiceToDelete({ categoryName, service });
    setDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    setCatalog((prevCatalog) => ({
      ...prevCatalog,
      [serviceToDelete.categoryName]: prevCatalog[
        serviceToDelete.categoryName
      ].filter((s) => s !== serviceToDelete.service),
    }));
    setDeleteDialogOpen(false);
    setServiceToDelete(null);
  };

  const filteredServices = useMemo(() => {
    return Object.entries(catalog).flatMap(([categoryName, services]) =>
      services.filter((service) =>
        Object.values(service).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      ).map(service => ({ ...service, categoryName }))
    );
  }, [catalog, searchTerm]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Service Catalog
      </Typography>
      <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
        <TextField
          variant="outlined"
          placeholder="Search services..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box>
          <Tooltip title="Add New Service">
            <IconButton onClick={() => handleAddNewService("Default")}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Toggle Column Visibility">
            <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        <MenuItem>
          <FormGroup>
            {Object.keys(visibleColumns).map((column) => (
              <FormControlLabel
                key={column}
                control={
                  <Checkbox
                    checked={visibleColumns[column]}
                    onChange={(e) =>
                      setVisibleColumns((prev) => ({
                        ...prev,
                        [column]: e.target.checked,
                      }))
                    }
                  />
                }
                label={column}
              />
            ))}
          </FormGroup>
        </MenuItem>
      </Menu>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="services table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Category</StyledTableCell>
              {Object.entries(visibleColumns).map(
                ([column, isVisible]) =>
                  isVisible && (
                    <StyledTableCell key={column}>{column}</StyledTableCell>
                  )
              )}
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newService && (
              <TableRow>
                <TableCell>
                  <TextField
                    fullWidth
                    value={newService.categoryName}
                    onChange={(e) =>
                      setNewService((prev) => ({
                        ...prev,
                        categoryName: e.target.value,
                      }))
                    }
                  />
                </TableCell>
                {Object.entries(visibleColumns).map(
                  ([column, isVisible]) =>
                    isVisible && (
                      <TableCell key={column}>
                        <TextField
                          fullWidth
                          value={newService[column]}
                          onChange={(e) =>
                            setNewService((prev) => ({
                              ...prev,
                              [column]: e.target.value,
                            }))
                          }
                        />
                      </TableCell>
                    )
                )}
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={handleSaveNewService}
                    disabled={Object.values(newService).some(
                      (value) => !value
                    )}
                  >
                    <SaveIcon />
                  </IconButton>
                  <IconButton onClick={() => setNewService(null)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}
            {filteredServices.map((service) => (
              <TableRow key={service.serviceID}>
                <TableCell>{service.categoryName}</TableCell>
                {Object.entries(visibleColumns).map(
                  ([column, isVisible]) =>
                    isVisible && (
                      <TableCell key={column}>
                        {column === "Description" ? (
                          <DescriptionTextField
                            fullWidth
                            variant="standard"
                            value={
                              editedServices[service.categoryName]?.[
                                service.serviceID
                              ]?.[column] ?? service[column]
                            }
                            onChange={(e) =>
                              handleEdit(
                                service.categoryName,
                                service.serviceID,
                                column,
                                e.target.value
                              )
                            }
                            title={
                              editedServices[service.categoryName]?.[
                                service.serviceID
                              ]?.[column] ?? service[column]
                            }
                          />
                        ) : (
                          <StyledTextField
                            fullWidth
                            variant="standard"
                            type={
                              column === "List Price" ||
                              column === "Misc Expenses" ||
                              column === "Time Per"
                                ? "text"
                                : "text"
                            }
                            value={
                              editedServices[service.categoryName]?.[
                                service.serviceID
                              ]?.[column] ?? service[column]
                            }
                            onChange={(e) => {
                              let value = e.target.value;
                              if (
                                column === "List Price" ||
                                column === "Misc Expenses"
                              ) {
                                value = value.replace(/[^0-9.]/g, "");
                                value = value === "" ? "" : parseFloat(value);
                              } else if (column === "Time Per") {
                                value = value.replace(/[^0-9]/g, "");
                                value = value === "" ? "" : parseInt(value);
                              }
                              handleEdit(
                                service.categoryName,
                                service.serviceID,
                                column,
                                value
                              );
                            }}
                            InputProps={{
                              startAdornment:
                                column === "List Price" ||
                                column === "Misc Expenses" ? (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ) : null,
                              endAdornment:
                                column === "Time Per" ? (
                                  <InputAdornment position="end">
                                    min
                                  </InputAdornment>
                                ) : null,
                            }}
                          />
                        )}
                      </TableCell>
                    )
                )}
                <TableCell>
                  <Tooltip title="Save">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        handleSave(service.categoryName, service.serviceID)
                      }
                      disabled={
                        !editedServices[service.categoryName]?.[service.serviceID]
                      }
                    >
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Show Tasks">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigate(
                          `/tasks/${service.categoryName}/${service.serviceID}`
                        )
                      }
                    >
                      <ListAltIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      color="error"
                      onClick={() =>
                        handleDeleteService(service.categoryName, service)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={saving} fullWidth maxWidth="sm">
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Saving Changes
          </Typography>
          <Box sx={{ width: "100%", mt: 2 }}>
            <LinearProgress variant="determinate" value={savingProgress} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
            <Typography variant="body2" color="text.secondary">
              {`${Math.round(savingProgress)}%`}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogContent>
          <Typography>Are you sure you wish to delete this service?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ServicesCatalog;