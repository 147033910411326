import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { createServiceRequest, fetchUsers } from "../../ApiServices/ApiServices";
import { fetchAuthSession } from "aws-amplify/auth";
import serviceTypesData from "../Data/serviceTypes.json";
import Priority from "../Data/priority.json";
import Locations from "../Data/locations.json";
import AssignmentGroups from "../Data/AssignmentGroups.json";
import {
  Container, Grid, Card, CardContent, Typography, TextField,
  Button, Box, CircularProgress, FormControl, InputLabel, Stack, LinearProgress, Dialog, DialogContent,
  Autocomplete
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const NewService = () => {
  const [numberOfServices, setNumberOfServices] = useState(1);
  const theme = useTheme();
  const navigate = useNavigate();
  const licenseKey = "8650c58b40f5c8d3e3325c7ce4c3e6e2cb88ba80b9f4d544dcc0dfae76acd8c23797d5ceada346b74919ef9392ab5d410122d067defacec4a0545316f8c57553";
  const [dueDate, setDueDate] = useState(null);
  const [formattedDueDate, setFormattedDueDate] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const [currentUserInfo, setCurrentUserInfo] = useState(null);
  const [userInfo, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState('');
  const [selectedPriority, setSelectedPriority] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [assignee, setAssignee] = useState('');
  const [selectedAssignmentGroup, setSelectedAssignmentGroup] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [note, setNote] = useState('');
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentService, setCurrentService] = useState(0);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [users, authSession] = await Promise.all([
          fetchUsers(licenseKey),
          fetchAuthSession()
        ]);

        setUsers(users.response);
        const { idToken } = authSession.tokens ?? {};
        const email = idToken.payload.email;
        setCurrentUser(email);
        const requester = users.response.find(user => user.EmailAddress === email);
        setCurrentUserInfo(requester);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  const handleDueDateChange = (newValue) => {
    setDueDate(newValue);
    setFormattedDueDate(newValue ? newValue.toISOString().split('T')[0] : '');
  };

  const handleNumberOfServicesChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,3}$/.test(value) && (value === '' || (parseInt(value) >= 1 && parseInt(value) <= 999))) {
      setNumberOfServices(value === '' ? 1 : parseInt(value));
    }
  };

  const handleSelectChange = (event, newValue) => {
    setSelectedType(newValue);
    
    const selectedService = serviceTypesData.response[0].Services.find(
      service => service["Service Type"] === newValue
    );
    
    setSelectedTasks(selectedService?.Tasks?.map(task => ({
      ...task,
      assignmentGroup: '',
      dueDate: null
    })) ?? []);
  };

  const handleTaskAssignmentGroupChange = (index, value) => {
    setSelectedTasks(prevTasks => {
      const updatedTasks = [...prevTasks];
      updatedTasks[index].assignmentGroup = value;
      return updatedTasks;
    });
  };

  const calculateDueDate = (responseTime) => {
    const currentDate = new Date();
    const hoursToAdd = parseInt(responseTime.match(/\d+/)[0]);
    const dueDate = new Date(currentDate.getTime() + hoursToAdd * 60 * 60 * 1000);
    return dueDate.toLocaleString();
  };

  const createServicePayload = useCallback(() => {
    const currentDate = new Date().toLocaleString();
    const selectedPriorityData = Priority.find(item => item.priority === selectedPriority);
    const dueDate = calculateDueDate(selectedPriorityData.responseTime);
    const serviceDesc = serviceTypesData.response[0].Services.find(type => type["Service Type"] === selectedType)?.Description;

    return {
      DueDate: formattedDueDate,
      CreationTime: currentDate,
      RequestType: selectedType,
      Priority: selectedPriority,
      Impact: "1",
      AssignedTo: assignee,
      RequestDescription: serviceDesc,
      RequesterName: `${currentUserInfo.FirstName} ${currentUserInfo.LastName}`,
      RequesterEmail: currentUserInfo.EmailAddress,
      RequesterPhoneNumber: currentUserInfo.Phone,
      RelatedTickets: [],
      ServiceStatus: "New",
      Organization: selectedCustomer,
      Location: selectedLocation,
      Floor: selectedFloor,
      AssignedGroup: selectedAssignmentGroup,
      Note: note || "No Initial Description",
      Tasks: selectedTasks
    };
  }, [formattedDueDate, selectedType, selectedPriority, assignee, currentUserInfo, selectedCustomer, selectedLocation, selectedFloor, selectedAssignmentGroup, note, selectedTasks]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setProgress(0);
      setCurrentService(0);
  
      const totalServices = numberOfServices;
      const payload = createServicePayload();
  
      for (let i = 0; i < totalServices; i++) {
        setCurrentService(i + 1);
        
        const response = await createServiceRequest(payload);
        if (response.response !== "Success") {
          throw new Error(`Failed to submit Service Request ${i + 1}`);
        }
  
        setProgress(((i + 1) / totalServices) * 100);
  
        // Add a 1.5-second delay if there are more requests to submit
        if (i < totalServices - 1) {
          await new Promise(resolve => setTimeout(resolve, 1500));
        }
      }
  
      alert("All Service Requests submitted successfully!");
      navigate("/services");
    } catch (error) {
      console.error("Error submitting Service Requests:", error.message);
      alert("Failed to submit all Service Requests. Please try again later.");
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ pt: 3 }}>
      <Grid container spacing={3}>
        {/* Requester Info Card */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>Create New Service Request</Typography>
              <Typography variant="h6">Requester Info</Typography>
              <Typography>Name: {currentUserInfo?.FirstName || "N/A"} {currentUserInfo?.LastName || "N/A"}</Typography>
              <Typography>Email: {currentUser}</Typography>
              <Typography>Phone: {currentUserInfo?.Phone || "N/A"}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Service Type Selection */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  value={selectedType}
                  onChange={handleSelectChange}
                  options={serviceTypesData.response[0].Services.map(type => type["Service Type"])}
                  renderInput={(params) => <TextField {...params} label="Select Service Type" />}
                />
              </FormControl>
              {selectedType && (
                <Box mt={2}>
                  <Typography variant="h6">{selectedType}</Typography>
                  <Typography>Description: {serviceTypesData.response[0].Services.find(type => type["Service Type"] === selectedType)?.Description}</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Associated Tasks */}
        {selectedTasks.length > 0 && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Associated Tasks</Typography>
                <Stack spacing={2}>
                  {selectedTasks.map((task, index) => (
                    <Box key={index}>
                      <Typography variant="subtitle1">{task.name}</Typography>
                      <Typography variant="body2">{task.description}</Typography>
                      <FormControl fullWidth margin="normal">
                        <Autocomplete
                          value={task.assignmentGroup}
                          onChange={(event, newValue) => handleTaskAssignmentGroupChange(index, newValue)}
                          options={AssignmentGroups.map(group => group.AssignmentGroup)}
                          renderInput={(params) => <TextField {...params} label="Assignment Group" />}
                        />
                      </FormControl>
                    </Box>
                  ))}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        )}

        {/* Priority Selection */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  value={selectedPriority}
                  onChange={(event, newValue) => setSelectedPriority(newValue)}
                  options={Priority.map(type => type.priority)}
                  renderInput={(params) => <TextField {...params} label="Select Priority" />}
                  renderOption={(props, option) => (
                    <li {...props} style={{ color: Priority.find(type => type.priority === option)?.color }}>
                      {option}
                    </li>
                  )}
                />
              </FormControl>
              {selectedPriority && (
                <Typography>Description: {Priority.find(type => type.priority === selectedPriority)?.description}</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Assignment Group Selection */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  value={selectedAssignmentGroup}
                  onChange={(event, newValue) => setSelectedAssignmentGroup(newValue)}
                  options={AssignmentGroups.map(group => group.AssignmentGroup)}
                  renderInput={(params) => <TextField {...params} label="Service Owner Assignment Group" />}
                />
              </FormControl>
              {selectedAssignmentGroup && (
                <Typography>Members: {AssignmentGroups.find(group => group.AssignmentGroup === selectedAssignmentGroup)?.Members.join(", ")}</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Assign To Selection */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  value={assignee}
                  onChange={(event, newValue) => setAssignee(newValue)}
                  options={userInfo.map(user => user.EmailAddress)}
                  renderInput={(params) => <TextField {...params} label="Assign To" />}
                  renderOption={(props, option) => {
                    const user = userInfo.find(u => u.EmailAddress === option);
                    return (
                      <li {...props}>{`${user.FirstName} ${user.LastName}`}</li>
                    );
                  }}
                />
              </FormControl>
              {assignee && (
                <Box mt={2}>
                  <Typography>First: {userInfo.find(user => user.EmailAddress === assignee)?.FirstName || "N/A"}</Typography>
                  <Typography>Last: {userInfo.find(user => user.EmailAddress === assignee)?.LastName || "N/A"}</Typography>
                  <Typography>Phone: {userInfo.find(user => user.EmailAddress === assignee)?.Phone || "N/A"}</Typography>
                  <Typography>Email: {userInfo.find(user => user.EmailAddress === assignee)?.EmailAddress || "N/A"}</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Organization Selection */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  value={selectedCustomer}
                  onChange={(event, newValue) => {
                    setSelectedCustomer(newValue);
                    setSelectedLocation("");
                  }}
                  options={Object.keys(Locations)}
                  renderInput={(params) => <TextField {...params} label="Choose Organization" />}
                />
              </FormControl>
            </CardContent>
          </Card>
        </Grid>

        {/* Location Selection */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  value={selectedLocation}
                  onChange={(event, newValue) => setSelectedLocation(newValue)}
                  options={selectedCustomer ? Locations[selectedCustomer].BUILDINGS.map(building => building["Building Name"]) : []}
                  renderInput={(params) => <TextField {...params} label="Choose Location" />}
                  disabled={!selectedCustomer}
                />
              </FormControl>
            </CardContent>
          </Card>
        </Grid>

        {/* Floor Selection */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  value={selectedFloor}
                  onChange={(event, newValue) => setSelectedFloor(newValue)}
                  options={[...Array(20)].map((_, index) => (index + 1).toString())}
                  renderInput={(params) => <TextField {...params} label="Choose Floor" />}
                  disabled={!selectedLocation}
                />
              </FormControl>
            </CardContent>
          </Card>
        </Grid>

        {/* Due Date and Number of Services */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Due Date"
                      value={dueDate}
                      onChange={handleDueDateChange}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Number of Services"
                    type="text"
                    value={numberOfServices}
                    onChange={handleNumberOfServicesChange}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      maxLength: 3
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Initial Description */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <TextField
                fullWidth
                label="Initial Description"
                multiline
                rows={5}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Enter initial description here"
                inputProps={{ maxLength: 500 }}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Box sx={{ my: 2 }}> 
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={submitting || !selectedCustomer || !selectedLocation || !assignee || !selectedPriority || !selectedType || !selectedAssignmentGroup || !dueDate}
              onClick={handleSubmit}
            >
              {submitting ? "Submitting..." : "Submit"}
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Progress Dialog */}
      <Dialog open={isLoading} fullWidth maxWidth="sm">
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Creating Service Requests
          </Typography>
          <Typography variant="body2" gutterBottom>
            Creating service {currentService} of {numberOfServices}
          </Typography>
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
            <Typography variant="body2" color="text.secondary">
              {`${Math.round(progress)}%`}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default NewService;