const awsmobile = {
    aws_project_region: "us-east-2",
    aws_cognito_identity_pool_id:
      "us-east-2:e2061c14-6f31-4b43-945b-e76f77bc44c2",
    aws_cognito_region: "us-east-2",
    aws_user_pools_id: "us-east-2_oaGXn36jK",
    aws_user_pools_web_client_id: "b2ouo65ecu82ific2r97cgcoe",
    aws_appsync_graphqlEndpoint:
      "https://jprmf4dlng.appsync-api.us-east-2.amazonaws.com/graphql",
    aws_appsync_region: "us-east-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  };
  
  export default awsmobile;
