import React, { useState } from 'react';
import './resizableTextField.css'; // Stylesheet for the component

const ResizableTextField = ({ label, defaultValue, onChange }) => {
  const [value, setValue] = useState(defaultValue || '');

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (onChange) {
      onChange(newValue); // Notify the parent component of the value change
    }
  };

  return (
    <div className="resizable-text-field">
      <label className="resizable-text-field-label">{label}</label>
      <textarea
        className="resizable-text-field-input"
        value={value}
        onChange={(event) => handleChange(event)} // Pass the event to handleChange
      />
    </div>
  );
};

export default ResizableTextField;

