import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import PeopleIcon from "@mui/icons-material/People";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom';
import { fetchAllAssignmentGroups } from "../../ApiServices/ApiServices";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2),
}));

const StyledTextField = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
});

const AssignmentGroupManagement = () => {
  const [assignmentGroups, setAssignmentGroups] = useState({});
  const [loading, setLoading] = useState(true);
  const [newGroup, setNewGroup] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [newGroupError, setNewGroupError] = useState("");

  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchGroups = useCallback(async () => {
    setLoading(true);
    try {
      const groups = await fetchAllAssignmentGroups();
      setAssignmentGroups(groups);
    } catch (error) {
      console.error("Error fetching assignment groups:", error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const handleAddNewGroup = () => {
    setNewGroup({
      name: "AG-",
      totalMembers: 0,
      projectName: "",
      serviceName: "",
      taskName: "",
      members: [],
    });
    setNewGroupError("");
  };

  const handleNewGroupNameChange = (e) => {
    let value = e.target.value;
    
    if (!value.startsWith("AG-")) {
      value = "AG-" + value;
    }
    
    value = value.slice(0, 64);

    setNewGroup((prev) => ({ ...prev, name: value }));

    if (value.length <= 3) {
      setNewGroupError("Name must be longer than 'AG-'");
    } else if (assignmentGroups[value]) {
      setNewGroupError("This group name already exists");
    } else {
      setNewGroupError("");
    }
  };

  const handleSaveNewGroup = async () => {
    if (newGroupError) return;

    setAssignmentGroups((prev) => ({
      ...prev,
      [newGroup.name]: {
        ...newGroup,
        totalMembers: 0,
      },
    }));
    setNewGroup(null);
  };

  const handleDeleteGroup = (groupName) => {
    setGroupToDelete(groupName);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    setAssignmentGroups((prev) => {
      const newGroups = { ...prev };
      delete newGroups[groupToDelete];
      return newGroups;
    });
    setDeleteDialogOpen(false);
    setGroupToDelete(null);
  };

  const filteredGroups = useMemo(() => {
    return Object.entries(assignmentGroups).filter(([groupName, group]) =>
      groupName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      group.projectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      group.serviceName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      group.taskName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [assignmentGroups, searchTerm]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Assignment Group Management
      </Typography>
      <Box sx={{ mb: 2, display: "flex", flexDirection: isSmallScreen ? 'column' : 'row', gap: 2, justifyContent: "space-between" }}>
        <TextField
          variant="outlined"
          placeholder="Search Groups..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth={isSmallScreen}
        />
        <Tooltip title="Add New Assignment Group">
          <IconButton onClick={handleAddNewGroup}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: { xs: '100%', sm: 650 } }} aria-label="assignment groups table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Group Name</StyledTableCell>
              <StyledTableCell>Total Members</StyledTableCell>
              <StyledTableCell>Project Name</StyledTableCell>
              <StyledTableCell>Service Name</StyledTableCell>
              <StyledTableCell>Task Name</StyledTableCell>
              <StyledTableCell align="right" sx={{ pr: 3 }}>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newGroup && (
              <TableRow>
                <TableCell>
                  <StyledTextField
                    fullWidth
                    value={newGroup.name}
                    onChange={handleNewGroupNameChange}
                    error={!!newGroupError}
                    helperText={newGroupError}
                    inputProps={{
                      maxLength: 96,
                    }}
                  />
                </TableCell>
                <TableCell>{newGroup.totalMembers}</TableCell>
                <TableCell>
                  <StyledTextField
                    fullWidth
                    value={newGroup.projectName}
                    onChange={(e) => setNewGroup(prev => ({ ...prev, projectName: e.target.value }))}
                  />
                </TableCell>
                <TableCell>
                  <StyledTextField
                    fullWidth
                    value={newGroup.serviceName}
                    onChange={(e) => setNewGroup(prev => ({ ...prev, serviceName: e.target.value }))}
                  />
                </TableCell>
                <TableCell>
                  <StyledTextField
                    fullWidth
                    value={newGroup.taskName}
                    onChange={(e) => setNewGroup(prev => ({ ...prev, taskName: e.target.value }))}
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    onClick={handleSaveNewGroup}
                    disabled={!newGroup.name || !!newGroupError}
                  >
                    <SaveIcon />
                  </IconButton>
                  <IconButton onClick={() => setNewGroup(null)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}
            {filteredGroups.map(([groupName, group]) => (
              <TableRow key={groupName}>
                <TableCell>{groupName}</TableCell>
                <TableCell>{group.totalMembers}</TableCell>
                <TableCell>{group.projectName}</TableCell>
                <TableCell>{group.serviceName}</TableCell>
                <TableCell>{group.taskName}</TableCell>
                <TableCell align="right">
                  <Tooltip title="Manage Members">
                    <IconButton
                      color="primary"
                      onClick={() => navigate(`/assignmentGroups/${groupName}/members`, { state: { groupDetails: group } })}
                    >
                      <PeopleIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteGroup(groupName)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogContent>
          <Typography>Are you sure you wish to delete this assignment group?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AssignmentGroupManagement;