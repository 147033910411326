import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  DarkModeOutlined,
  WbSunnyOutlined,
} from "@mui/icons-material";
import icon from "./icon.png";

const ResponsiveAppBar = ({ toggleColorMode, colorMode, versionNumber, menuItems, hasAccess }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (route) => {
    navigate(route);
    handleClose();
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme.palette.mode === "light"
          ? theme.palette.background.default
          : theme.palette.background.paper,
        color: theme.palette.mode === "light"
          ? "black"
          : theme.palette.text.primary,
        zIndex: 50,
      }}
    >
      <Toolbar sx={{ minHeight: { xs: 48, sm: 56, md: 64 }, py: 0.5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <img
            src={icon}
            alt="Logo"
            style={{ 
              marginRight: "10px",
              height: isXsScreen ? '24px' : isSmScreen ? '32px' : '40px',
              width: 'auto'
            }}
          />
          {!isSmScreen && (
            <Typography 
              variant="h6" 
              component="div" 
              sx={{ 
                flexGrow: 1,
                fontSize: isMdScreen ? '1rem' : '1.25rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              Cloud Ingenuity Service Desk
            </Typography>
          )}
        </Box>
        
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography 
            variant="subtitle2" 
            sx={{ 
              display: { xs: 'none', sm: 'block' },
              fontSize: isMdScreen ? '0.7rem' : '0.8rem'
            }}
          >
            v{versionNumber}
          </Typography>
          <IconButton 
            onClick={toggleColorMode} 
            color="inherit"
            size={isXsScreen ? 'small' : 'medium'}
          >
            {colorMode === "dark" ? <DarkModeOutlined /> : <WbSunnyOutlined />}
          </IconButton>
          <IconButton
            size={isXsScreen ? 'small' : 'medium'}
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {menuItems.map((item) => (
            hasAccess(item.access) && (
              <MenuItem key={item.route} onClick={() => handleMenuItemClick(item.route)}>
                {item.label}
              </MenuItem>
            )
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default ResponsiveAppBar;