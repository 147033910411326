import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
  Toolbar, Checkbox, IconButton, Tooltip, Paper, Box, Typography, FormControlLabel, Switch,
  Collapse, Button
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { 
  FilterList, 
  FileDownloadOutlined, 
  CheckBoxOutlined, 
  KeyboardArrowUp, 
  KeyboardArrowDown 
} from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import TaskList from './TaskList';

const headCells = [
  { id: 'RequestNumber', numeric: false, disablePadding: true, label: 'Request Number' },
  { id: 'RequestType', numeric: false, disablePadding: false, label: 'Request Type' },
  { id: 'RequestDescription', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'Priority', numeric: false, disablePadding: false, label: 'Priority' },
  { id: 'ServiceStatus', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
  { id: 'go', numeric: false, disablePadding: false, label: 'Go' },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all services' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected, onDownloadSelected, onDownloadAll } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          Service Requests
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Export Selected">
          <IconButton onClick={onDownloadSelected}>
            <CheckBoxOutlined />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterList />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title="Export All">
        <IconButton onClick={onDownloadAll}>
          <FileDownloadOutlined />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

const ServiceRow = ({ service, isItemSelected, labelId, handleClick, getStatusColor, getPriorityColor }) => {
  const [open, setOpen] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const navigate = useNavigate();

  const handleToggle = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const handleCompletionPercentageChange = (percentage) => {
    setCompletionPercentage(percentage);
  };

  const getCompletionColor = (percentage) => {
    const hue = percentage * 1.2; 
    return `hsl(${hue}, 100%, 40%)`; 
  };

  const handleGoToService = (event) => {
    event.stopPropagation();
    navigate(`/service/${service.RequestID}`);
  };

  const handleGoToTask = (taskID) => {
    navigate(`/task/${taskID}/${service.RequestID}`);
  };

  return (
    <>
      <TableRow
        hover
        onClick={(event) => handleClick(event, service.RequestID)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
        sx={{ cursor: 'pointer' }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {service.RequestNumber}
        </TableCell>
        <TableCell>{service.RequestType}</TableCell>
        <TableCell>{service.RequestDescription}</TableCell>
        <TableCell>
          <span style={{ color: getPriorityColor(service.Priority) }}>
            {service.Priority}
          </span>
        </TableCell>
        <TableCell>
          <span style={{ color: getStatusColor(service.ServiceStatus) }}>
            {service.ServiceStatus}
          </span>
        </TableCell>
        <TableCell>
          <IconButton size="small" onClick={handleToggle}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoToService}
          >
            Go
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Assigned To</TableCell>
                    <TableCell>Organization</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Floor</TableCell>
                    <TableCell>Requester Name</TableCell>
                    <TableCell>Creation Time</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Completed</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">{service.AssignedTo}</TableCell>
                    <TableCell>{service.Organization}</TableCell>
                    <TableCell>{service.Location}</TableCell>
                    <TableCell>{service.Floor}</TableCell>
                    <TableCell>{service.RequesterName}</TableCell>
                    <TableCell>{service.CreationTime}</TableCell>
                    <TableCell>{service.DueDate}</TableCell>
                    <TableCell>
                      <Typography 
                        component="span" 
                        sx={{ 
                          fontWeight: 'bold',
                          color: getCompletionColor(completionPercentage)
                        }}
                      >
                        {`${completionPercentage.toFixed(2)}%`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleGoToService}
                      >
                        Edit Service
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <TaskList 
                requestID={service.RequestID} 
                isOpen={open} 
                onCompletionPercentageChange={handleCompletionPercentageChange}
                onGoToTask={handleGoToTask}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ServiceTable = ({ services, getStatusColor, getPriorityColor, onDownloadSelected, onDownloadAll }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('RequestNumber');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = services.map((n) => n.RequestID);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - services.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar 
          numSelected={selected.length} 
          onDownloadSelected={() => onDownloadSelected(selected)}
          onDownloadAll={onDownloadAll}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={services.length}
            />
            <TableBody>
              {services
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((service, index) => {
                  const isItemSelected = isSelected(service.RequestID);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <ServiceRow
                      key={service.RequestID}
                      service={service}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      handleClick={handleClick}
                      getStatusColor={getStatusColor}
                      getPriorityColor={getPriorityColor}
                    />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={services.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
};

export default ServiceTable;