import React, { createContext, useState, useEffect, useContext } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { fetchUsers } from './components/ApiServices/ApiServices';
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const licenseKey = process.env.REACT_APP_LICENSE_KEY;
  const [allUsers, setUsers] = useState([]);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      fetchAllUsers();
      console.log("Checking Storage...")
      console.log("Stored User: ", storedUser);
      setUser(JSON.parse(storedUser));
    } else {
      console.log("No User Stored")
      fetchAllUsers();
    }
  }, []);

  const fetchAllUsers = async () => {
    try {
      console.log("Getting All Users...")
      const users = await fetchUsers(licenseKey);
      console.log("Fetch Auth...")
      const { authToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      const email = idToken.payload.email;
      const requester = users.response.find(
        (user) => user.EmailAddress === email
      );
      setUser(requester);
      setUsers(users);
      console.log("Context User Retrieved:", requester);
      console.log("Context Users Retrieved:", users);
      localStorage.setItem("user", JSON.stringify(requester));
      localStorage.setItem("users", JSON.stringify(allUsers));
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const clearUser = () => {
    setUser(null);
    localStorage.removeItem("user");
  };

  return (
    <UserContext.Provider value={{ allUsers, user, setUser, fetchAllUsers, clearUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
