import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Autocomplete, Chip } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

// Import TechConsumption data
import techConsumptionData from '../Data/TechConsumption.json';

const TechnicianCalendar = () => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: '',
    start: null,
    end: null,
  });
  const [selectedTechnicians, setSelectedTechnicians] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  
  const colorIndex = useRef(0);

  const colors = [
    '#58c1fb', '#2b9ddb', '#3686b5', '#38759c', '#376887',
    '#355d78', '#32546b', '#2f4c60', '#2d4556', '#293e4d'
  ];

  const getNextColor = () => {
    const color = colors[colorIndex.current];
    colorIndex.current = (colorIndex.current + 1) % colors.length;
    return color;
  };

  useEffect(() => {
    // Extract unique technician IDs
    const techIds = [...new Set(techConsumptionData.techConsumption.map(tech => tech.id))];
    setTechnicians(techIds);

    // Convert TechConsumption data to events
    const techEvents = techConsumptionData.techConsumption.flatMap(tech => 
      tech.dates.map(date => ({
        title: `${tech.id} - Project`,
        start: dayjs(date.start, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        end: dayjs(date.end, 'MM/DD/YYYY').add(1, 'day').format('YYYY-MM-DD'),
        color: getNextColor(),
        techId: tech.id,
      }))
    );
    setEvents(techEvents);
    setFilteredEvents(techEvents);
  }, []);

  useEffect(() => {
    if (selectedTechnicians.length === 0) {
      setFilteredEvents(events);
    } else {
      const filtered = events.filter(event => selectedTechnicians.includes(event.techId));
      setFilteredEvents(filtered);
    }
  }, [selectedTechnicians, events]);

  const handleDateSelect = (selectInfo) => {
    setNewEvent({
      title: '',
      start: selectInfo.startStr,
      end: selectInfo.endStr,
    });
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewEvent({ title: '', start: null, end: null });
  };

  const handleEventAdd = () => {
    if (newEvent.title && newEvent.start && newEvent.end) {
      const updatedEvents = [...events, { ...newEvent, color: getNextColor() }];
      setEvents(updatedEvents);
      handleDialogClose();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
          <Autocomplete
            multiple
            id="technician-filter"
            options={technicians}
            value={selectedTechnicians}
            onChange={(event, newValue) => {
              setSelectedTechnicians(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Filter Technicians"
                placeholder="Select technicians"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            style={{ width: 300, marginRight: '1rem' }}
          />
        </div>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek'
          }}
          events={filteredEvents}
          selectable={true}
          select={handleDateSelect}
          height="100%"
        />
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Add New Booking</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Technician Name - Project"
              type="text"
              fullWidth
              value={newEvent.title}
              onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
            />
            <DatePicker
              label="Start Date"
              value={dayjs(newEvent.start)}
              onChange={(date) => setNewEvent({ ...newEvent, start: date.format('YYYY-MM-DD') })}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
            />
            <DatePicker
              label="End Date"
              value={dayjs(newEvent.end)}
              onChange={(date) => setNewEvent({ ...newEvent, end: date.format('YYYY-MM-DD') })}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleEventAdd}>Add</Button>
          </DialogActions>
        </Dialog>
      </div>
    </LocalizationProvider>
  );
};

export default TechnicianCalendar;