import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { fetchIncidents } from '../../ApiServices/ApiServices';
import { fetchAuthSession } from "aws-amplify/auth";
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TableSortLabel, Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip, FormControlLabel,
  Switch, Button, CircularProgress, Container, TextField, InputAdornment, Select, MenuItem,
  Grid, useMediaQuery, Autocomplete
} from '@mui/material';
import { FilterList, FileDownloadOutlined, AddCircleOutlineOutlined, CheckBoxOutlined } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import statusData from '../Data/status.json';
import priorityData from '../Data/priority.json';
import SearchIcon from "@mui/icons-material/Search";
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

export default function IncidentTable({ signOut, userName, colorMode }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [incidents, setIncidents] = useState([]);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('IncidentNumber');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loading, setLoading] = useState(true);
  const [filterState, setFilterState] = useState('all');
  const [allIncidents, setAllIncidents] = useState([]);
  const [exportIncidents, setExportIncidents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [ticketTypeFilter, setTicketTypeFilter] = useState('ALL');
  const [locationFilter, setLocationFilter] = useState('ALL');
  const [priorityFilter, setPriorityFilter] = useState('ALL');
  const [statusFilter, setStatusFilter] = useState('ALL');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const uniqueTicketTypes = useMemo(() => ['ALL', ...new Set(incidents.map(incident => incident.IncidentType))], [incidents]);
  const uniqueLocations = useMemo(() => ['ALL', ...new Set(incidents.map(incident => incident.Location))], [incidents]);
  const priorities = useMemo(() => ['ALL', ...priorityData.map(priority => priority.priority)], []);
  const statuses = useMemo(() => ['ALL', ...statusData.status.map(status => status.name)], []);


  const headCells = [
    { id: 'IncidentNumber', numeric: false, disablePadding: true, label: 'Ticket Number' },
    { id: 'IncidentType', numeric: false, disablePadding: false, label: 'Ticket Type' },
    { id: 'AssignedGroup', numeric: false, disablePadding: false, label: 'Group'},
    { id: 'IncidentDescription', numeric: false, disablePadding: false, label: 'Description' },
    { id: 'CreationTime', numeric: false, disablePadding: false, label: 'Created'},
    { id: 'Priority', numeric: false, disablePadding: false, label: 'Priority' },
    { id: 'IncidentStatus', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'IncidentID', numeric: false, disablePadding: false, label: 'Go' }
  ];

  const getStatusColor = (statusName) => {
    const status = statusData.status.find(s => s.name === statusName);
    return status ? status.color : theme.palette.text.primary;
  };
  
  const getPriorityColor = (priorityName) => {
    const priority = priorityData.find(s => s.priority === priorityName);
    return priority ? priority.color : theme.palette.text.primary;
  };

  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        const email = idToken.payload.email;
        console.log("Email Retrieved: ", email);
        const data = await fetchIncidents();
        setAllIncidents(data.response.body);
        setExportIncidents(data.response.body);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching Incidents:", error);
      }
    };

    fetchIncidentData();
  }, []);

  useEffect(() => {
    const filterIncidents = async () => {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      const email = idToken.payload.email;
      let filteredIncidents;
      if (filterState === 'myTickets') {
        filteredIncidents = allIncidents.filter(incident => incident.AssignedTo === email);
      } else if (filterState === 'assignedByMe') {
        filteredIncidents = allIncidents.filter(incident => incident.RequesterEmail === email);
      } else {
        filteredIncidents = allIncidents;
      }
      setIncidents(filteredIncidents);
    };

    filterIncidents();
  }, [filterState, allIncidents]);

  const filteredIncidents = useMemo(() => {
    return incidents.filter(incident =>
      (ticketTypeFilter === 'ALL' || incident.IncidentType === ticketTypeFilter) &&
      (locationFilter === 'ALL' || incident.Location === locationFilter) &&
      (priorityFilter === 'ALL' || incident.Priority === priorityFilter) &&
      (statusFilter === 'ALL' || incident.IncidentStatus === statusFilter) &&
      Object.values(incident).some(
        value =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [incidents, searchTerm, ticketTypeFilter, locationFilter, priorityFilter, statusFilter]);

  const pieChartData = useMemo(() => {
    const data = {};
    filteredIncidents.forEach(incident => {
      const key = incident.IncidentType;
      if (data[key]) {
        data[key]++;
      } else {
        data[key] = 1;
      }
    });
    return Object.entries(data).map(([label, value]) => ({ label, value }));
  }, [filteredIncidents]);

  const totalIncidents = useMemo(() => pieChartData.reduce((sum, item) => sum + item.value, 0), [pieChartData]);

  const colorPalette = [
    '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', 
    '#F06292', '#AED581', '#7986CB', '#4DB6AC', '#FFD54F',
    '#9575CD', '#4DD0E1', '#81C784', '#DCE775', '#64B5F6',
    '#FFB74D', '#A1887F', '#90A4AE', '#BA68C8', '#4FC3F7'
  ];

  const chartSizing = {
    width: isSmallScreen ? 280 : 350,
    height: isSmallScreen ? 280 : 350,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredIncidents.map((n) => n.IncidentID);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleFilterChange = () => {
    if (filterState === 'all') {
      setFilterState('myTickets');
    } else if (filterState === 'myTickets') {
      setFilterState('assignedByMe');
    } else {
      setFilterState('all');
    }
  };

  const handleMenuItemClick = (route) => {
    console.log("Handle Menu Item Click", route);
    navigate(route);
  };

  const convertToCSV = (data) => {
    if (data.length === 0) return '';

    const headers = [
      'IncidentNumber', 'IncidentStatus', 'RequesterName', 'RequesterEmail', 'RequesterPhoneNumber',
      'IncidentType', 'Impact', 'Priority', 'DueDate', 'IncidentDescription', 'AssignedTo',
      'SLATarget', 'CreationTime', 'IncidentID', 'Location', 'Organization', 'ClosureDetails',
      'ConfigurationItem', 'AssignedGroup'
    ];

    const csvRows = [];
    csvRows.push(headers.join(','));
    data.forEach((item) => {
      const row = headers.map(header => `"${item[header] || ''}"`);
      csvRows.push(row.join(','));
    }); 
    return csvRows.join('\n');
  };

  const downloadSelectedCSV = () => {
    const selectedIncidents = filteredIncidents.filter((incident) => selected.includes(incident.IncidentID));
    const csv = convertToCSV(selectedIncidents);
    const csvData = new Blob([csv], { type: 'text/csv' });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'selected_incidents.csv');
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  const downloadCSV = () => {
    const csv = convertToCSV(exportIncidents);
    const csvData = new Blob([csv], { type: 'text/csv' });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'incidents.csv');
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all tickets' }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  function EnhancedTableToolbar(props) {
    const { numSelected, downloadSelectedCSV, downloadCSV, filterState, handleFilterChange, handleMenuItemClick } = props;
  
    const getFilterTitle = () => {
      switch (filterState) {
        case 'myTickets':
          return 'My Incidents';
        case 'assignedByMe':
          return 'Incidents Assigned By Me';
        default:
          return 'All Incidents';
      }
    };
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
            {getFilterTitle()}
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Export Selected">
            <IconButton onClick={downloadSelectedCSV}>
              <CheckBoxOutlined />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={filterState === 'all' ? 'My Tickets' : filterState === 'myTickets' ? 'Assigned To': 'All'}>
            <IconButton onClick={handleFilterChange}>
              <FilterList />
            </IconButton>
          </Tooltip>
          )}

          <Tooltip title="Create New Incident">
            <IconButton onClick={() => handleMenuItemClick('/new-incident')}>
              <AddCircleOutlineOutlined />
            </IconButton>
          </Tooltip>
    
          <Tooltip title="Export All">
            <IconButton onClick={downloadCSV}>
              <FileDownloadOutlined />
            </IconButton>
          </Tooltip>
        </Toolbar>
      );
    }
    
    EnhancedTableToolbar.propTypes = {
      numSelected: PropTypes.number.isRequired,
    };
  
    const visibleRows = React.useMemo(
      () =>
        stableSort(filteredIncidents, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        ),
      [order, orderBy, page, rowsPerPage, filteredIncidents],
    );
  
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      );
    }
  
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredIncidents.length) : 0;
  
    return (
      <Container maxWidth="xl" sx={{ py: 3, px: { xs: 1, sm: 2, md: 3 } }}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>
            <EnhancedTableToolbar 
              numSelected={selected.length} 
              downloadSelectedCSV={downloadSelectedCSV}
              downloadCSV={downloadCSV} 
              filterState={filterState}
              handleFilterChange={handleFilterChange}
              handleMenuItemClick={() => handleMenuItemClick('/new-incident')}
            />
            <Box sx={{ p: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search incidents..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredIncidents.length}
                  headCells={headCells}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    const isItemSelected = isSelected(row.IncidentID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.IncidentID)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.IncidentID}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.IncidentNumber}
                        </TableCell>
                        <TableCell align="left">{row.IncidentType}</TableCell>
                        <TableCell align="left">{row.AssignedGroup}</TableCell>
                        <TableCell align="left">{row.IncidentDescription}</TableCell>
                        <TableCell align="left">{row.CreationTime}</TableCell>
                        <TableCell align="left">
                          <span style={{ color: getPriorityColor(row.Priority) }}>
                            {row.Priority}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          <span style={{ color: getStatusColor(row.IncidentStatus) }}>
                            {row.IncidentStatus}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={`/incident/${row.IncidentID}`}
                          >
                            Go
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              component="div"
              count={filteredIncidents.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Compact View"
          />
        </Box>
  
        {/* Pie Chart Section */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>Incident Type Distribution</Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              value={ticketTypeFilter}
              onChange={(event, newValue) => {
                setTicketTypeFilter(newValue || 'ALL');
              }}
              options={uniqueTicketTypes}
              renderInput={(params) => <TextField {...params} label="Ticket Type" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              value={locationFilter}
              onChange={(event, newValue) => {
                setLocationFilter(newValue || 'ALL');
              }}
              options={uniqueLocations}
              renderInput={(params) => <TextField {...params} label="Location" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              value={priorityFilter}
              onChange={(event, newValue) => {
                setPriorityFilter(newValue || 'ALL');
              }}
              options={priorities}
              renderInput={(params) => <TextField {...params} label="Priority" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              value={statusFilter}
              onChange={(event, newValue) => {
                setStatusFilter(newValue || 'ALL');
              }}
              options={statuses}
              renderInput={(params) => <TextField {...params} label="Status" />}
            />
          </Grid>
        </Grid>
          <Box sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2
          }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: isSmallScreen ? '100%' : '50%'
            }}>
              <PieChart
                series={[
                  {
                    data: pieChartData,
                    innerRadius: 80,
                    outerRadius: isSmallScreen ? 130 : 160,
                    paddingAngle: 2,
                    cornerRadius: 4,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30 },
                    valueFormatter: (value) => `${value.value} (${((value.value / totalIncidents) * 100).toFixed(1)}%)`,
                  },
                ]}
                colors={pieChartData.map((_, index) => colorPalette[index % colorPalette.length])}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: 'white',
                    fontWeight: 'bold',
                  },
                }}
                {...chartSizing}
                legend={{ hidden: true }}
              />
            </Box>
            <Box sx={{
              width: isSmallScreen ? '100%' : '50%',
              maxHeight: isSmallScreen ? 'auto' : 350,
              overflowY: isSmallScreen ? 'visible' : 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: isSmallScreen ? '80%' : '100%'
              }}>
                {pieChartData.map((item, index) => (
                  <Box key={index} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1,
                    width: '100%'
                  }}>
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: colorPalette[index % colorPalette.length],
                        mr: 1,
                        flexShrink: 0
                      }}
                    />
                    <Typography variant="body2" noWrap>
                      {item.label}: {item.value} ({((item.value / totalIncidents) * 100).toFixed(1)}%)
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
  
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }