import React from 'react';
import { Box, Typography } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const SmallPieChart = ({ data, title, count }) => {
  const chartSize = 200;

  const chartData = Object.entries(data)
    .filter(([_, { count }]) => count > 0)
    .map(([status, { count, color }]) => ({
      name: status,
      value: count,
      color: color
    }));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {chartData.length > 0 ? (
        <ResponsiveContainer width={chartSize} height={chartSize}>
          <PieChart>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              innerRadius={chartSize * 0.2}
              outerRadius={chartSize * 0.4}
              cornerRadius={chartSize * 0.03}
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <Box width={chartSize} height={chartSize} display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body2">No data</Typography>
        </Box>
      )}
      <Typography variant="h6" align="center">{title}</Typography>
      <Typography variant="h4" align="center">{count}</Typography>
    </Box>
  );
};

export default SmallPieChart;