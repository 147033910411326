import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Autocomplete,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const ProjectMembersSection = ({ projectData, setProjectData, allUsers }) => {
  const [selectedUser, setSelectedUser] = useState(null);

  const handleAddUser = () => {
    if (selectedUser && !projectData.selectedUsers.some(user => user.EmailAddress === selectedUser.EmailAddress)) {
      setProjectData(prevData => ({
        ...prevData,
        selectedUsers: [...prevData.selectedUsers, selectedUser]
      }));
      setSelectedUser(null);
    }
  };

  const handleRemoveUser = (emailToRemove) => {
    setProjectData(prevData => ({
      ...prevData,
      selectedUsers: prevData.selectedUsers.filter(user => user.EmailAddress !== emailToRemove)
    }));
  };

  const isLoading = !allUsers || allUsers.length === 0;

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Add Project Members
        </Typography>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Autocomplete
              id="project-users"
              options={allUsers}
              getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
              value={selectedUser}
              onChange={(event, newValue) => {
                setSelectedUser(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.EmailAddress === value.EmailAddress}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Project Member"
                  placeholder="User"
                />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddUser}
              disabled={!selectedUser}
              sx={{ mt: 2 }}
            >
              Add User to Project
            </Button>
          </>
        )}

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Selected Project Members
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectData.selectedUsers.map((user) => (
                <TableRow key={user.EmailAddress}>
                  <TableCell>{user.FirstName}</TableCell>
                  <TableCell>{user.LastName}</TableCell>
                  <TableCell>{user.EmailAddress}</TableCell>
                  <TableCell>{user.Phone}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleRemoveUser(user.EmailAddress)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default ProjectMembersSection;