import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

// Import the status data
import statusData from '../../Data/srStatus.json';

const StatusChart = ({ statusChartData }) => {
  // Calculate the total
  const total = statusChartData.reduce((sum, item) => sum + item.value, 0);

  // Create a map of existing data
  const existingDataMap = new Map(statusChartData.map(item => [item.label, item]));

  // Create a complete dataset including zero counts for the legend
  const completeChartData = statusData.status.map(status => ({
    value: existingDataMap.get(status.name)?.value || 0,
    label: status.name,
    color: status.color,
  }));

  // Create a dataset for the pie chart, excluding zero values
  const pieChartData = completeChartData.filter(item => item.value > 0);

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" align="center" gutterBottom>All Services Progress</Typography>
        <Typography variant="h5" align="center" gutterBottom>Total: {total}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <PieChart
            series={[
              {
                data: pieChartData,
                innerRadius: 80,
                outerRadius: 120,
                cornerRadius: 4,
                paddingAngle: 2,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 10, additionalRadius: -30 },
              },
            ]}
            height={300}
            width={300}
            margin={{ right: 5, left: 5, top: 5, bottom: 5 }}
            legend={{ hidden: true }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
          {completeChartData.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: item.color,
                  mr: 1,
                }}
              />
              <Typography variant="body2">
                {item.label}: {item.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default StatusChart;