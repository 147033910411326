import React from 'react';
import { Grid, Card, CardContent, FormControl, TextField, Autocomplete, InputAdornment, Button, Box } from '@mui/material';

const ReportingForm = ({ 
  organization, setOrganization, 
  location, setLocation, 
  assignmentGroup, setAssignmentGroup,
  serviceType, setServiceType,
  requestNumber, setRequestNumber,
  assignedTo, setAssignedTo,
  projectNumber, setProjectNumber,
  organizationOptions, locationOptions, assignmentGroupOptions, serviceTypeOptions, userOptions,
  projects,
  handleGenerateReport, loading
}) => {
  const handleOrganizationChange = (event, newValue) => {
    setOrganization(newValue);
    setLocation('');
  };

  const handleLocationChange = (event, newValue) => setLocation(newValue);
  const handleAssignmentGroupChange = (event, newValue) => setAssignmentGroup(newValue);
  const handleServiceTypeChange = (event, newValue) => setServiceType(newValue);
  
  const handleRequestNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, '').slice(0, 5);
    setRequestNumber(value);
  };

  const handleAssignedToChange = (event, newValue) => setAssignedTo(newValue ? newValue.value : '');
  const handleProjectChange = (event, newValue) => setProjectNumber(newValue ? newValue.ProjectNumber : '');

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <FormControl fullWidth margin="normal">
              <Autocomplete
                value={organization}
                onChange={handleOrganizationChange}
                options={organizationOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Choose Organization" variant="outlined" />
                )}
              />
            </FormControl>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <FormControl fullWidth margin="normal">
              <Autocomplete
                value={location}
                onChange={handleLocationChange}
                options={locationOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Choose Location" variant="outlined" />
                )}
                disabled={!organization}
              />
            </FormControl>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <FormControl fullWidth margin="normal">
              <Autocomplete
                value={assignmentGroup}
                onChange={handleAssignmentGroupChange}
                options={assignmentGroupOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Assignment Group" variant="outlined" />
                )}
              />
            </FormControl>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <FormControl fullWidth margin="normal">
              <Autocomplete
                value={serviceType}
                onChange={handleServiceTypeChange}
                options={serviceTypeOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Service Type" variant="outlined" />
                )}
              />
            </FormControl>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Request Number"
                variant="outlined"
                value={requestNumber}
                onChange={handleRequestNumberChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">REQ</InputAdornment>,
                }}
                inputProps={{
                  maxLength: 5,
                }}
                helperText="Enter up to 5 digits"
              />
            </FormControl>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <FormControl fullWidth margin="normal">
              <Autocomplete
                value={userOptions.find(option => option.value === assignedTo) || null}
                onChange={handleAssignedToChange}
                options={userOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Assigned To" variant="outlined" />
                )}
              />
            </FormControl>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <FormControl fullWidth margin="normal">
              <Autocomplete
                value={projects.find(project => project.ProjectNumber === projectNumber) || null}
                onChange={handleProjectChange}
                options={projects}
                getOptionLabel={(option) => `${option.ProjectName}`}
                renderInput={(params) => (
                  <TextField {...params} label="Project" variant="outlined" />
                )}
              />
            </FormControl>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}> 
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerateReport}
            disabled={loading}
          >
            {loading ? 'Generating...' : 'Generate Report'}
          </Button>
        </Box>
      </Grid>
    </Grid>

    


  );
};

export default ReportingForm;