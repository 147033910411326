import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { ReactComponent as TAMULogo } from '../Graphics/TAMU.svg';

const incidentTypes = [
  { name: "Hardware Failure", description: "Issues related to malfunctioning hardware components such as servers, switches, routers, or desktop/laptop computers." },
  { name: "Verkada Access Control", description: "Hardware is malfunctioning." },
  { name: "Customer Issue", description: "Customer has Miscellaneous Issue preventing work from being completed." },
  { name: "Software Failure", description: "Problems arising from bugs, errors, or glitches in software applications, operating systems, or utilities." },
  { name: "Network Outage", description: "Complete or partial loss of network connectivity resulting in service disruption." },
  { name: "Performance Degradation", description: "Sluggish system performance impacting the end-user experience, possibly due to resource constraints or bottlenecks." },
  { name: "Security Breach", description: "Unauthorized access, data breach, malware infection, or other security incidents compromising the confidentiality, integrity, or availability of IT services." },
  { name: "User Error", description: "Incidents caused by mistakes or incorrect actions performed by end-users, such as accidental data deletion or misconfiguration." },
  { name: "Configuration Change", description: "Planned or unplanned modifications to IT infrastructure, software configurations, or system settings leading to service disruption or performance issues." },
  { name: "Environmental Issue", description: "Problems resulting from environmental factors such as power outages, temperature fluctuations, or physical damage to equipment." },
];

const colorPalette = [
  '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', 
  '#F06292', '#AED581', '#7986CB', '#4DB6AC', '#FFD54F',
  '#9575CD', '#4DD0E1', '#81C784', '#DCE775', '#64B5F6',
  '#FFB74D', '#A1887F', '#90A4AE', '#BA68C8', '#4FC3F7'
];

const changeColorPalette = [
  '#1976D2', '#2196F3', '#64B5F6', '#90CAF9', '#BBDEFB',
  '#607D8B', '#90A4AE', '#B0BEC5', '#CFD8DC', '#ECEFF1'
];

const changeTypes = [
  { name: "Hardware Change", description: "Planned or unplanned modifications to hardware components.", ola_definition: "Resolution within 4 hours during business hours; 8 hours outside of business hours." },
  { name: "Software Update", description: "Updating software applications or operating systems to newer versions.", ola_definition: "Resolution within 8 hours during business hours; 12 hours outside of business hours." },
  { name: "Infrastructure Upgrade", description: "Upgrading network infrastructure or server hardware.", ola_definition: "Resolution within 4 hours during business hours; 8 hours outside of business hours." },
  { name: "Policy Change", description: "Modifying organizational policies or procedures.", ola_definition: "Resolution within 1 business day." },
  { name: "Process Improvement", description: "Implementing changes to improve existing processes.", ola_definition: "Resolution within 4 hours during business hours; 8 hours outside of business hours." }
];

export default function CustomerPortal({ userName }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleProblemClick = () => {
    console.log('Problem button clicked');
    // navigate('/report-problem');
  };

  const handleChangeClick = () => {
    console.log('Change button clicked');
    // navigate('/request-change');
  };

  const chartData = useMemo(() => {
    return incidentTypes.map((type, index) => ({
      value: Math.floor(Math.random() * 100) + 1,
      label: type.name,
      color: colorPalette[index % colorPalette.length]
    }));
  }, []);

  const changeChartData = useMemo(() => {
    return changeTypes.map((type, index) => ({
      value: Math.floor(Math.random() * 50) + 1,
      label: type.name,
      color: changeColorPalette[index % changeColorPalette.length]
    }));
  }, []);

  const totalIncidents = useMemo(() => chartData.reduce((sum, item) => sum + item.value, 0), [chartData]);
  const totalChanges = useMemo(() => changeChartData.reduce((sum, item) => sum + item.value, 0), [changeChartData]);

  const chartSizing = {
    width: isSmallScreen ? 280 : 350,
    height: isSmallScreen ? 280 : 350,
  };

  return (
    <Container maxWidth="lg" sx={{ pt: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: 'center',
                  gap: 2
                }}
              >
                <TAMULogo width={200} height={200} />
                <Typography 
                  variant="h5" 
                  gutterBottom={isMobile}
                  sx={{ 
                    textAlign: isMobile ? 'center' : 'left',
                    mt: isMobile ? 2 : 0
                  }}
                >
                  Texas A&M University Support Portal
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>Need Assistance?</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    sx={{ py: 3 }}
                    onClick={handleProblemClick}
                  >
                    I have a problem
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    sx={{ py: 3 }}
                    onClick={handleChangeClick}
                  >
                    I need to make a change
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Incident Types Overview</Typography>
              <Box sx={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2
              }}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: isSmallScreen ? '100%' : '50%'
                }}>
                  <PieChart
                    series={[
                      {
                        data: chartData,
                        innerRadius: 80,
                        outerRadius: isSmallScreen ? 130 : 160,
                        paddingAngle: 2,
                        cornerRadius: 4,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30 },
                        valueFormatter: (value) => `${value.value} (${((value.value / totalIncidents) * 100).toFixed(1)}%)`,
                      },
                    ]}
                    colors={chartData.map(item => item.color)}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: 'bold',
                      },
                    }}
                    {...chartSizing}
                    legend={{ hidden: true }}
                  />
                </Box>
                <Box sx={{
                  width: isSmallScreen ? '100%' : '50%',
                  maxHeight: isSmallScreen ? 'auto' : 350,
                  overflowY: isSmallScreen ? 'visible' : 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: isSmallScreen ? '80%' : '100%'
                  }}>
                    {chartData.map((item, index) => (
                      <Box key={index} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        width: '100%'
                      }}>
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            backgroundColor: item.color,
                            mr: 1,
                            flexShrink: 0
                          }}
                        />
                        <Typography variant="body2" noWrap>
                          {item.label}: {item.value} ({((item.value / totalIncidents) * 100).toFixed(1)}%)
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Change Types Overview</Typography>
              <Box sx={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2
              }}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: isSmallScreen ? '100%' : '50%'
                }}>
                  <PieChart
                    series={[
                      {
                        data: changeChartData,
                        innerRadius: 80,
                        outerRadius: isSmallScreen ? 130 : 160,
                        paddingAngle: 2,
                        cornerRadius: 4,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30 },
                        valueFormatter: (value) => `${value.value} (${((value.value / totalChanges) * 100).toFixed(1)}%)`,
                      },
                    ]}
                    colors={changeChartData.map(item => item.color)}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: 'bold',
                      },
                    }}
                    {...chartSizing}
                    legend={{ hidden: true }}
                  />
                </Box>
                <Box sx={{
                  width: isSmallScreen ? '100%' : '50%',
                  maxHeight: isSmallScreen ? 'auto' : 350,
                  overflowY: isSmallScreen ? 'visible' : 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: isSmallScreen ? '80%' : '100%'
                  }}>
                    {changeChartData.map((item, index) => (
                      <Box key={index} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        width: '100%'
                      }}>
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            backgroundColor: item.color,
                            mr: 1,
                            flexShrink: 0
                          }}
                        />
                        <Typography variant="body2" noWrap>
                          {item.label}: {item.value} ({((item.value / totalChanges) * 100).toFixed(1)}%)
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}