import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Paper,
  TextField,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  CircularProgress,
  Snackbar,
  Tooltip,
  LinearProgress,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CodeIcon from '@mui/icons-material/Code';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import { dracula, github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
  jsx,
  javascript,
  typescript,
  python,
  java,
  cpp,
  csharp,
  go,
  rust,
  swift,
  kotlin,
  ruby,
  php,
  sql,
  bash,
  json,
  yaml,
  markdown,
  css,
  markup,
  scss,
  sass,
  less,
  graphql,
  handlebars,
  docker,
} from 'react-syntax-highlighter/dist/esm/languages/prism';
import jsPDF from 'jspdf';
import icon from "../Menu/icon.png";

// Register languages for syntax highlighting
[jsx, javascript, typescript, python, java, cpp, csharp, go, rust, swift, kotlin, ruby, php, sql, bash, json, yaml, markdown, css, markup, scss, sass, less, graphql, handlebars, docker].forEach(
  language => SyntaxHighlighter.registerLanguage(language.name, language)
);

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: 'auto',
  marginTop: theme.spacing(4),
  width: '95%',
  maxWidth: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    borderRadius: '20px',
  },
});

const FileInput = styled('input')({
  display: 'none',
});

const TypingEffect = ({ content, onComplete }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < content.length) {
      const timer = setTimeout(() => {
        setDisplayedText(prevText => prevText + content[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, 20); // Adjust typing speed here
      return () => clearTimeout(timer);
    } else if (onComplete) {
      onComplete();
    }
  }, [currentIndex, content, onComplete]);

  return <Typography style={{ whiteSpace: 'pre-wrap' }}>{displayedText}</Typography>;
};

const PromptWindow = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [isConnecting, setIsConnecting] = useState(true);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentlyTyping, setCurrentlyTyping] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [processingProgress, setProcessingProgress] = useState(0);
  const fileInputRef = useRef(null);
  const websocketRef = useRef(null);
  const bufferRef = useRef('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';

  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'js':
      case 'jsx':
      case 'ts':
      case 'tsx':
      case 'html':
      case 'css':
      case 'json':
        return <CodeIcon />;
      case 'txt':
      case 'md':
        return <DescriptionIcon />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <ImageIcon />;
      default:
        return <InsertDriveFileIcon />;
    }
  };

  const handleExportPDF = () => {
    const latestAnswer = messages.filter(m => m.isResponse).pop();
    if (latestAnswer) {
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const margin = 15;
      const maxWidth = pageWidth - 2 * margin;
      const lineHeight = 7;
      
      doc.addImage(icon, 'PNG', 5, 5);

      doc.setFontSize(16);
      doc.text('IngenuityAI™ Answer', pageWidth / 2, margin, { align: 'center' });
      
      doc.setFontSize(12);
      const splitText = doc.splitTextToSize(latestAnswer.text, maxWidth);
      
      let cursorY = margin + 10;
      
      splitText.forEach((line) => {
        if (cursorY + lineHeight > pageHeight - margin * 2) {
          doc.addPage();
          cursorY = margin;
        }
        doc.text(line, margin, cursorY);
        cursorY += lineHeight;
      });
      
      const timestamp = new Date().toLocaleString();
      doc.setFontSize(10);
      
      // Check if we need to add a new page for the timestamp
      if (cursorY + lineHeight > pageHeight - margin) {
        doc.addPage();
        cursorY = margin;
      }
      
      doc.text(`Generated on: ${timestamp}`, margin, pageHeight - margin);
  
      doc.save('ingenuity_answer.pdf');
  
      setSnackbarMessage('PDF exported successfully!');
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage('No answer to export yet.');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    connectWebSocket();
    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, []);

  const connectWebSocket = () => {
    websocketRef.current = new WebSocket('wss://98ish4gx6g.execute-api.us-east-1.amazonaws.com/production/');

    websocketRef.current.onopen = () => {
      setConnected(true);
      setIsConnecting(false);
      setSnackbarMessage('Connected to IngenuityAI');
      setSnackbarOpen(true);
    };

    websocketRef.current.onclose = () => {
      setConnected(false);
      setIsConnecting(false);
      setSnackbarMessage('Disconnected from IngenuityAI');
      setSnackbarOpen(true);
    };

    websocketRef.current.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        if(data.chunk) {
          bufferRef.current += data.chunk;
          try {
            const parsedData = JSON.parse(bufferRef.current);
            setCurrentlyTyping({ text: parsedData.content[0].text, isResponse: true });
          } catch (parseError) {
            // If parsing fails, it means we don't have the complete JSON yet
            console.log("Incomplete JSON received, waiting for more chunks...");
          }
      
          if (data.isLastChunk) {
            // All chunks received, finalize the message
            const parsedData = JSON.parse(bufferRef.current);
            const completeMessage = { text: parsedData.content[0].text, isResponse: true };
            setMessages(prevMessages => [...prevMessages, completeMessage]);
            setCurrentlyTyping(null);
            setIsMessageLoading(false);
            bufferRef.current = '';
          }
        }
      } catch (error) {
        console.error("Error Processing WebSocket Message", error);
      }
    }
  };

  const handleSubmit = async () => {
    if (inputValue.trim() !== '' || attachedFiles.length > 0) {
      const newMessage = { text: inputValue, files: [...attachedFiles], isResponse: false };
      setMessages(prevMessages => [...prevMessages, newMessage]);

      if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
        setIsMessageLoading(true);
        setUploadProgress(0);
        bufferRef.current = ''; // Clear the buffer for the new message
        let payload;
        if (attachedFiles.length > 0) {
          const fileContents = await Promise.all(attachedFiles.map((file, index) => 
            readFileAsText(file, (progress) => {
              setUploadProgress(prevProgress => 
                (prevProgress * index + progress) / (index + 1)
              );
            })
          ));
          payload = {
            content: inputValue,
            files: fileContents.map((content, index) => ({
              name: attachedFiles[index].name,
              content: content
            }))
          };
        } else {
          payload = {
            question: inputValue
          };
        }
        try {
          console.log("Sending Payload: ", payload);
          websocketRef.current.send(JSON.stringify(payload));
        } catch (error) {
          console.error('Error sending message:', error);
          setSnackbarMessage('Error sending message. Please try again.');
          setSnackbarOpen(true);
          setIsMessageLoading(false);
        }
      } else {
        setSnackbarMessage('WebSocket is not connected');
        setSnackbarOpen(true);
      }

      setInputValue('');
      setAttachedFiles([]);
    }
  };

  const readFileAsText = (file, onProgress) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          onProgress(progress);
        }
      };
      reader.readAsText(file);
    });
  };

  const MAX_FILE_SIZE = 5 * 1024 * 1024;

  const handleFileAttach = (event) => {
    const newFiles = Array.from(event.target.files).filter(file => file.size <= MAX_FILE_SIZE);
    if (newFiles.length < event.target.files.length) {
      setSnackbarMessage('Some files were too large and were not attached');
      setSnackbarOpen(true);
    }
    setAttachedFiles(prevFiles => [...prevFiles, ...newFiles]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const removeAttachedFile = (fileName) => {
    setAttachedFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  const acceptedFileTypes = '.js,.jsx,.ts,.tsx,.swift,.txt,.md,.json,.html,.css';

  const renderMessageContent = (text) => {
    // Split the text into paragraphs
    const paragraphs = text.split('\n');
    
    return paragraphs.map((paragraph, index) => {
      // Check if the paragraph is a numbered list item
      if (/^\d+\./.test(paragraph)) {
        const items = paragraph.split('\n');
        return (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography component="div">
              {items.map((item, itemIndex) => (
                <Typography key={itemIndex} paragraph>
                  {item}
                </Typography>
              ))}
            </Typography>
          </Box>
        );
      } else {
        return (
          <Typography key={index} paragraph>
            {paragraph}
          </Typography>
        );
      }
    });
  };

  const renderMessage = (message) => {
    if (message === currentlyTyping) {
      return (
        <>
          <TypingEffect
            content={message.text}
            onComplete={() => setCurrentlyTyping(null)}
          />
          <LinearProgress 
            variant="determinate" 
            value={processingProgress} 
            sx={{ mt: 1 }}
          />
        </>
      );
    }
    
    return renderMessageContent(message.text);
  };

  if (isConnecting) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StyledPaper elevation={3}>
      <Typography variant="h5" gutterBottom align="center">
        IngenuityAI™ ©
      </Typography>
      <Box 
        sx={{ 
          height: isSmallScreen ? '50vh' : '60vh', 
          overflowY: 'auto', 
          mb: 2,
          border: '1px solid #e0e0e0',
          borderRadius: '4px',
          padding: 2,
          position: 'relative',
          width: '100%',
        }}
      >
        <List>
          {messages.map((message, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography color={message.isResponse ? "primary" : "textPrimary"}>
                      {message.isResponse ? "IngenuityAI: " : "You: "}
                    </Typography>
                  }
                  secondary={renderMessage(message)}
                />
              </ListItem>
              {index < messages.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledTextField
            fullWidth
            variant="outlined"
            placeholder="Ask Ingenuity Anything..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSubmit()}
            multiline
            maxRows={4}
            InputProps={{
              endAdornment: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={() => fileInputRef.current.click()}>
                    <AttachFileIcon />
                  </IconButton>
                  <IconButton onClick={handleExportPDF}>
                    <PictureAsPdfIcon />
                  </IconButton>
                  {isMessageLoading && (
                    <CircularProgress size={20} sx={{ ml: 1 }} />
                  )}
                  <FileInput
                    type="file"
                    multiple
                    ref={fileInputRef}
                    onChange={handleFileAttach}
                    accept={acceptedFileTypes}
                  />
                </Box>
              ),
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ml: 1 }}>
            <IconButton
              color="primary"
              onClick={handleSubmit}
              sx={{ borderRadius: '50%', height: '56px', width: '56px' }}
            >
              <SendIcon />
            </IconButton>
            <Tooltip title={connected ? "Connected to IngenuityAI" : "Disconnected from IngenuityAI"}>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <FiberManualRecordIcon 
                  sx={{ 
                    fontSize: '12px', 
                    color: connected ? 'green' : 'grey',
                    mr: 0.5
                  }} 
                />
                <Typography variant="caption">
                  {connected ? "Connected" : "Disconnected"}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </Box>
        {attachedFiles.length > 0 && (
          <Box sx={{ mt: 1 }}>
            <Typography variant="caption">
              Attached files:
            </Typography>
            {attachedFiles.map((file, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                {getFileIcon(file.name)}
                <Typography variant="caption" sx={{ ml: 1 }}>{file.name}</Typography>
                <IconButton size="small" onClick={() => removeAttachedFile(file.name)}>
                  <Typography variant="caption" color="error">Remove</Typography>
                </IconButton>
              </Box>
            ))}
            <LinearProgress 
              variant="determinate" 
              value={uploadProgress} 
              sx={{ mt: 1 }}
            />
          </Box>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </StyledPaper>
  );
};

export default PromptWindow;