import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getUserProjects } from '../../ApiServices/ApiServices';
import { useUser } from '../../../UserContext';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  FormControlLabel,
  Switch,
  Button,
  CircularProgress,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Alert
} from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import SearchIcon from "@mui/icons-material/Search";
import statusData from "../Data/srStatus.json";

const getStatusColor = (status) => {
  const statusItem = statusData.status.find(item => item.name === status);
  return statusItem ? statusItem.color : '#000000'; // Default to black if not found
};

export default function ProjectsTable() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useUser();
  const [projects, setProjects] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('ProjectNumber');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);

  const headCells = [
    { id: 'ProjectName', numeric: false, disablePadding: false, label: 'Project Name' },
    { id: 'ProjectStatus', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'ProjectOwner', numeric: false, disablePadding: false, label: 'Owner' },
    { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Customer Name' },
    { id: 'ProjectDescription', numeric: false, disablePadding: false, label: 'Description', hidden: { xs: true, sm: true, md: false } },
    { id: 'StartDate', numeric: false, disablePadding: false, label: 'Start Date', hidden: { xs: true, sm: false } },
    { id: 'EndDate', numeric: false, disablePadding: false, label: 'End Date', hidden: { xs: true, sm: false } },
    { id: 'ProjectMemberCount', numeric: true, disablePadding: false, label: 'Members', hidden: { xs: true, md: false } },
    { id: 'ServiceGroupsCount', numeric: true, disablePadding: false, label: 'Service Groups', hidden: { xs: true, md: false } },
    { id: 'ServiceDefinitionsCount', numeric: true, disablePadding: false, label: 'Service Definitions', hidden: { xs: true, lg: false } },
    { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
  ];

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      month: '2-digit', 
      day: '2-digit', 
      year: 'numeric' 
    });
  };

  useEffect(() => {
    const fetchUserProjects = async () => {
      
      try {
        if (user && user.EmailAddress) {
          console.log("User Retreived for Projects:", user.EmailAddress);
          const response = await getUserProjects(user.EmailAddress);
          if (response.body) {
            const parsedProjects = JSON.parse(response.body);
            console.log("Parsed Projects:", parsedProjects);
            if (Array.isArray(parsedProjects)) {
              setProjects(parsedProjects);
            } else {
              setError("Received projects data is not an array");
            }
          } else {
            setError("Failed to fetch projects data");
          }
        }
      } catch (error) {
        console.error("Error fetching user's projects:", error);
        setError("Error fetching projects: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProjects();
  }, [user]);

  const filteredProjects = useMemo(() => {
    if (!Array.isArray(projects)) return [];
    return projects.filter(project =>
      Object.values(project).some(
        value =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [projects, searchTerm]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleAddProject = () => {
    navigate('/projectCreation', { state: { savedProject: null } });
  };

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={headCell.hidden}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function EnhancedTableToolbar() {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          My Projects
        </Typography>
        <Tooltip title="Add New Project">
          <IconButton onClick={handleAddProject}>
            <AddCircleOutlineOutlined />
          </IconButton>
        </Tooltip>
      </Toolbar>
    );
  }

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredProjects, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, filteredProjects],
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Card sx={{ overflowX: 'auto' }}>
      <CardContent>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar />
            <Box sx={{ p: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search projects..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredProjects.length}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.ProjectNumber}
                      >
                        <TableCell align="left">{row.ProjectName}</TableCell>
                        <TableCell 
                          align="left" 
                          sx={{
                            color: getStatusColor(row.ProjectStatus),
                            fontWeight: 'bold'
                          }}
                        >
                          {row.ProjectStatus}
                        </TableCell>
                        <TableCell align="left">{row.ProjectOwner}</TableCell>
                        <TableCell align="left">{row.CustomerName}</TableCell>
                        <TableCell align="left" sx={headCells.find(cell => cell.id === 'ProjectDescription').hidden}>
                          {row.ProjectDescription}
                        </TableCell>
                        <TableCell align="left" sx={headCells.find(cell => cell.id === 'StartDate').hidden}>
                          {formatDate(row.StartDate)}
                        </TableCell>
                        <TableCell align="left" sx={headCells.find(cell => cell.id === 'EndDate').hidden}>
                          {formatDate(row.EndDate)}
                        </TableCell>
                        <TableCell align="right" sx={headCells.find(cell => cell.id === 'ProjectMemberCount').hidden}>
                          {row.ProjectMemberCount}
                        </TableCell>
                        <TableCell align="right" sx={headCells.find(cell => cell.id === 'ServiceGroupsCount').hidden}>
                          {row.ServiceGroupsCount}
                        </TableCell>
                        <TableCell align="right" sx={headCells.find(cell => cell.id === 'ServiceDefinitionsCount').hidden}>
                          {row.ServiceDefinitionsCount}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={`/projectCreation/${row.ProjectNumber}`}
                            state={{ projectNumber: row.ProjectNumber }}
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              component="div"
              count={filteredProjects.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Compact View"
          />
        </Box>
      </CardContent>
    </Card>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}