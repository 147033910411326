// TaskEditor.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TextField, Button, Typography, Box, CircularProgress, Dialog, DialogContent, LinearProgress,
  IconButton, DialogActions, Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Import the actual JSON catalog
import catalogData from '../Data/servicesDatabase.json';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2),
}));

const StyledTextField = styled(TextField)({
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none',
  },
});

const TaskEditor = () => {
  const { categoryName, serviceId } = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [editedTasks, setEditedTasks] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [savingProgress, setSavingProgress] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);

  useEffect(() => {
    const loadService = async () => {
      setLoading(true);
      const serviceData = catalogData.response[0][categoryName].find(s => s.serviceID === serviceId);
      setService(serviceData);
      setTasks(serviceData.Tasks || []);
      setLoading(false);
    };
    loadService();
  }, [categoryName, serviceId]);

  const handleEdit = (taskIndex, field, value) => {
    setEditedTasks(prev => ({
      ...prev,
      [taskIndex]: {
        ...(prev[taskIndex] || {}),
        [field]: value
      }
    }));
  };

  const handleSave = async (taskIndex) => {
    setSaving(true);
    setSavingProgress(0);

    // Simulate saving process
    for (let i = 0; i <= 100; i += 10) {
      setSavingProgress(i);
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    setTasks(prevTasks => {
      const newTasks = [...prevTasks];
      newTasks[taskIndex] = { ...newTasks[taskIndex], ...editedTasks[taskIndex] };
      return newTasks;
    });
    setEditedTasks(prev => {
      const newEdited = { ...prev };
      delete newEdited[taskIndex];
      return newEdited;
    });

    setSaving(false);
  };

  const handleAddNewTask = () => {
    const newTask = {
      level: (tasks.length + 1).toString(),
      name: '',
      description: '',
      timestamp: new Date().toISOString(),
      status: 'New'
    };
    setTasks(prevTasks => [...prevTasks, newTask]);
  };

  const handleDeleteTask = (task) => {
    setTaskToDelete(task);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    setTasks(prevTasks => prevTasks.filter(t => t !== taskToDelete));
    setDeleteDialogOpen(false);
    setTaskToDelete(null);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => navigate(-1)} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" component="h1">
          Tasks for {service['Service Type']}
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleAddNewTask}
        >
          Add New Task
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="tasks table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Level</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task, index) => (
              <TableRow key={index}>
                <TableCell>
                  <StyledTextField
                    fullWidth
                    variant="standard"
                    value={editedTasks[index]?.level ?? task.level}
                    onChange={(e) => handleEdit(index, 'level', e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <StyledTextField
                    fullWidth
                    variant="standard"
                    value={editedTasks[index]?.name ?? task.name}
                    onChange={(e) => handleEdit(index, 'name', e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <StyledTextField
                    fullWidth
                    variant="standard"
                    value={editedTasks[index]?.description ?? task.description}
                    onChange={(e) => handleEdit(index, 'description', e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <StyledTextField
                    fullWidth
                    variant="standard"
                    value={editedTasks[index]?.status ?? task.status}
                    onChange={(e) => handleEdit(index, 'status', e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSave(index)}
                    disabled={!editedTasks[index]}
                  >
                    Save
                  </Button>
                  <IconButton onClick={() => handleDeleteTask(task)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={saving} fullWidth maxWidth="sm">
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Saving Changes
          </Typography>
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress variant="determinate" value={savingProgress} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
            <Typography variant="body2" color="text.secondary">
              {`${Math.round(savingProgress)}%`}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogContent>
          <Typography>Are you sure you wish to delete this task?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">Yes, Delete</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TaskEditor;